angular.module('Plania').directive('dwg', ['$injector', '$state', '$interval', 'DwgMarkerService', 'TranslationService',
	function ($injector, $state, $interval, markerService, translationService) {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			showNetAreal: '=showNetAreal',
			guidDrawing: '=guidDrawing',
			showFilter: '=showFilter',
			selectedTheme: '=selectedTheme',
			isStaticTheme: '=isStaticTheme',
			shouldStopInterval: '=shouldStopInterval', //Used to stop rotationIntervals when modal is open
			alertDays: '=alertDays',
			guidQualityControl: '=guidQualityControl',
			highligthArea: '=highlightArea',
			update: '=update'
		},
		link: function link(scope, element, attrs) {
			var contentHeight = $(window).height() - 70;
			var drawingWrapper = $('#dwg-wrapper');
			var highlightInterval = null;

			var currentPageName = $state.current.name;

			if (drawingWrapper) {
				drawingWrapper.height(contentHeight);
			}

			if (!scope.selectedTheme) {
				scope.selectedTheme = 'standard';
			}

			scope.filters = { showMarkers: true, showAreaIds: false, showAllStatuses: false };
			var selectedAreas = [];
			var areas = []; //list of all areas available on this drawing
			var selectAll = false;
			var getStatusResult;
			var drawingGuidEstate; // Will be set after drawing is retrieved, can be used for queries afterwards

			scope.selectedEquipmentCategories = [];
			scope.equipmentCategoryColors = {};
			scope.legends = null;
			scope.collapsed = true;

			scope.selectedDoorLocks = [];
			scope.doorLockColors = {};


			var defaultPalette = [
				'#003049',
				'#D62828',
				'#F77F00',
				'#FCBF49',
				'#EAE2B7',
			];
			var equipmentPalette = angular.copy(defaultPalette);
			var doorLockPalette = angular.copy(defaultPalette);

			//all services registered here must implement:
			//onClick(guidArea, type) = function which will be called when user clicks a room or a marker
			//getStatus(guidAreas) = function which will be called to featch status for all areas
			var themeServices = {
				standard: $injector.get('StandardThemeService'),
				activity: $injector.get('ActivityThemeService'),
				controlList: $injector.get('ControlListThemeService'),
				cleaning: $injector.get('CleaningThemeService'),
				cleaningMaintenance: $injector.get('CleaningMaintenanceThemeService'),
				cleaningQualityControl: $injector.get('CleaningQualityControlThemeService'),
				request: $injector.get('RequestThemeService'),
				equipment: $injector.get('EquipmentThemeService'),
				doorLock: $injector.get('DoorLockThemeService')
			};

			var getPathId = function (guid) { return "#" + guid + (scope.showNetAreal ? "_net" : "_gross"); };

			function selectArea(guid) {
				var path = $('#' + guid).children()[0];
				if (!_.find(selectedAreas, { guid: guid })) {
					selectedAreas.push({ guid: guid, originalColor: $(path).attr('fill'), originalOpacity: Number($(path).attr('fill-opacity')) });
					$(path).attr('fill', '#5F5F5F');
					$(path).attr('fill-opacity', 1);
				}
				else {
					for (var i = 0; i < selectedAreas.length; i++) {
						var p = selectedAreas[i];
						if (p.guid === guid) {
							$(path).attr('fill', selectedAreas[i].originalColor);
							$(path).attr('fill-opacity', selectedAreas[i].originalOpacity);
							selectedAreas.splice(i, 1);
							break;
						}
					}
				}
			}

			var customEventHandler =
			{
				haltEventListeners: ['click', 'touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel', 'mousemove'],
				init: function (options) {
					var dragFlag = 0;
					var instance = options.instance,
						initialScale = 1,
						pannedX = 0,
						pannedY = 0;

					// Init Hammer
					this.hammer = Hammer(options.svgElement, {
						touchAction: 'auto'
						//inputClass: Hammer.TouchInput
					});

					this.hammer.get('pinch').set({ enable: true });

					// Handle pan
					this.hammer.on('pan panstart panend', function (ev) {
						// On pan start reset panned variables
						if (ev.type === 'panstart') {
							pannedX = 0;
							pannedY = 0;
						}

						// Pan only the difference
						if (ev.type === 'pan' || ev.type === 'panend') {
							instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY });
							pannedX = ev.deltaX;
							pannedY = ev.deltaY;
						}


					});

					//Handle pinch
					this.hammer.on('pinch pinchstart pinchend', function (ev) {
						// On pinch start remember initial zoom
						if (ev.type === 'pinchstart') {
							initialScale = instance.getZoom();
							instance.zoom(initialScale * ev.scale);
						}

						// On pinch zoom
						if (ev.type === 'pinch' || ev.type === 'pinchend') {
							instance.zoom(initialScale * ev.scale);
						}
					});

					options.svgElement.addEventListener('touchmove', function (e) { e.preventDefault(); });

					jQuery(".areaGroup").hover(function () {
						var jqObject = jQuery(this).children(getPathId(this.id));
						jqObject.attr('fill-opacity', Number(jqObject.attr('fill-opacity')) + 0.1);
						jqObject.attr('stroke-opacity', Number(jqObject.attr('stroke-opacity')) + 0.1);
					}, function () {
						var jqObject = jQuery(this).children(getPathId(this.id));
						jqObject.attr('fill-opacity', Number(jqObject.attr('fill-opacity')) - 0.1);
						jqObject.attr('stroke-opacity', Number(jqObject.attr('stroke-opacity')) - 0.1);
					});

					this.hammer.on('tap press', function (t) {
						if (t.target.id === "dwgDrawing") return;
						t.srcEvent.preventDefault();
						var guid = t.target.parentNode.id.replace('_marker', '');
						if (!guid) return;
						resetHighlight();

						var type = '';
						if (!t.target.id)
							type = t.target.parentNode.id.replace(guid + '_', '');
						else
							type = t.target.id.replace(guid + '_', '');

						var data = {};

						if (scope.selectedTheme === 'request' || scope.selectedTheme === 'cleaning')
							data.exitGuidAreaXCleaningTask = $(t.target).data('exitGuidAreaXCleaningTask');

						scope.shouldStopInterval = true;
						if (!scope.selectMultipleAreas) {
							themeServices[scope.selectedTheme].onClick(guid, type, data).then(function (response) {
								if (response && response.update) getStatuses();

								scope.shouldStopInterval = false;
							}, function (error) {
								scope.shouldStopInterval = false;
							});
						}
						else {
							selectArea(guid);
						}
					});
				},
				destroy: function () {
					this.hammer.destroy();
				}
			};

			scope.$watch('guidDrawing', function (newValue, oldValue) {
				if (newValue === oldValue) return;
				getDrawing();
			});

			scope.$watch('update', function (newValue, oldValue) {
				if (newValue === oldValue) return;
				getStatuses(true);
			});

			scope.$watch('highligthArea', function (newValue, oldValue) {
				if (newValue === oldValue || !newValue) return;
				highlightArea();
			});

			scope.$watch('alertDays', function (newValue, oldValue) {
				if (newValue === oldValue) return;
				themeServices.checklist.setAlertDays(newValue);
			});

			scope.$watch('selectedTheme', function (newValue, oldValue) {
				if (newValue === oldValue) return;
				scope.themeSelections = dwgThemeService.getAvailableThemes(scope.selectedTheme, scope.isStaticTheme);
				getStatuses();
				if (scope.selectMultipleAreas)
					scope.toggleMultipleAreaSelect();
			});

			scope.$watch('filters.showMarkers', function (newValue, oldValue) {
				if (newValue === oldValue) return;
				if (scope.selectedTheme === 'cleaning' || scope.selectedTheme === 'request' || scope.selectedTheme === 'activity') {
					if (newValue) {
						themeServices[scope.selectedTheme].showMarkers();
					} else {
						themeServices[scope.selectedTheme].hideMarkers();
					}
				} else {
					return;
				}
			});

			scope.$watch('filters.showAreaIds', function (newValue, oldValue) {
				if (newValue === oldValue) return;
				$('#areaId-label-coordinates').toggle(newValue);
			});

			scope.$watch('equipmentCategoryColors', function (newVal, oldVal) {
				if (scope.selectedTheme !== 'equipment') return;
				if (newVal === oldVal) return;
				if (Object.keys(newVal).length !== Object.keys(oldVal).length) return;
				var keys = Object.keys(newVal);
				keys.forEach(function (key) {
					if (newVal[key] !== oldVal[key]) {
						$('.areaGroup').find('tspan[guidEquipmentCategory=\"' + key + '\"]').each(function () {
							$(this).attr('fill', newVal[key]);
						});
					}
				});
			}, true);

			scope.$watch('doorLockColors', function (newVal, oldVal) {
				if (scope.selectedTheme !== 'doorLock') return;
				if (newVal === oldVal) return;
				if (Object.keys(newVal).length !== Object.keys(oldVal).length) return;
				var keys = Object.keys(newVal);
				keys.forEach(function (key) {
					if (newVal[key] !== oldVal[key]) {
						$('#doorlock-coordinates').find('tspan[guidDoorLock=\"' + key + '\"]').each(function () {
							$(this).attr('fill', newVal[key]);
						});
					}
				});
			}, true);

			scope.toggleLegendFilters = function () {
				scope.filters.showAllStatuses = !scope.filters.showAllStatuses;
				scope.legends = themeServices[scope.selectedTheme].getLegends(getStatusResult, scope.filters.showAllStatuses);
			};

			function highlightArea() {
				resetHighlight();
				var path = $('#' + scope.highligthArea).children()[0];
				if (!path) return;
				var count = 0;
				var originalOpacity = Number($(path).attr('fill-opacity'));
				$(path).attr('fill-opacity', originalOpacity + 0.3);
				$(path).attr('style', 'transition: fill-opacity .4s ease-in-out;');

				highlightInterval = $interval(function () {
					if (count % 2 === 0) {
						$(path).attr('fill-opacity', originalOpacity);
					} else {
						$(path).attr('fill-opacity', originalOpacity + 0.3);
					}
					count++;
				}, 700, currentPageName === 'dwg.view' ? null : 9);

				scope.highligthArea = null;
			}

			function resetHighlight() {
				if (highlightInterval)
					$interval.cancel(highlightInterval);
			}

			var resetIndicators = function () {
				//remove all colours set by previous theme
				areas.forEach(function (areaObject) {
					var path = $('#' + areaObject.GuidArea).children()[0];
					$(path).attr('fill', 'rgb(0,255,0)');
					$(path).attr('stroke-width', '0');
					$(path).attr('stroke-opacity', '0');
					$(path).attr('fill-opacity', '0');

					// Reset highlight transition
					$(path).attr('style', 'transition: none;');
				});
			};

			var resetMarkers = function () {
				if (areas.length < 1) return;
				areas.forEach(function (areaObject) {
					var markerGroup = $('#' + areaObject.GuidArea + '_marker')[0];
					if (markerGroup)
						$('#dwgDrawing').svg('get').remove(markerGroup);

				});
			};

			var resetPeriodicMarkers = function () {
				if (areas.length < 1) return;
				areas.forEach(function (areaObject) {
					var markerGroup = $('#' + areaObject.GuidArea + '_periodicMarker')[0];
					if (markerGroup)
						$('#dwgDrawing').svg('get').remove(markerGroup);

				});
			};

			// Update the indicators and markings
			function updateStatuses() {
				if (!scope.selectedTheme)
					return;

				if (scope.guidQualityControl) {
					themeServices[scope.selectedTheme].getStatus(scope.guidQualityControl, areas).then(
						function (result) { highlightArea(); },
						function (error) { repository.growl(error, 'danger'); }
					);
				} else {
					themeServices[scope.selectedTheme].getStatus(areas, scope.guidDrawing).then(
						function (result) { highlightArea(); },
						function (error) { repository.growl(error, 'danger'); }
					);
				}
			}

			function drawAreaIds() {
				if (areas.length < 1) return;

				areas.forEach(function (area) {
					var elements = $('#' + area.GuidArea).children();
					var markerGroup = $('#' + area.GuidArea + '_areaIdLabel')[0];

					if (markerGroup)
						$('#dwgDrawing').svg('get').remove(markerGroup);

					if (elements[0]) {
						var path = elements[0];
						markerService.addAreaIdLabel(path, area);
					}
				});
				$('#areaId-label-coordinates').toggle(scope.filters.showAreaIds);
			}

			function getStatuses() {
				if (areas.length < 1 || !scope.selectedTheme) return;

				var success = function (result) {
					highlightArea();
					drawAreaIds();
					getStatusResult = result;
					scope.legends = themeServices[scope.selectedTheme].getLegends(result);
				};

				var error = function (error) {
					repository.growl(error, 'danger');
					scope.legends = themeServices[scope.selectedTheme].getLegends();
				};

				if (scope.guidQualityControl)
					themeServices[scope.selectedTheme].getStatus(scope.guidQualityControl).then(success, error);
				else if (scope.selectedTheme === 'equipment')
					themeServices[scope.selectedTheme].getStatus(areas, scope.guidDrawing, scope.selectedEquipmentCategories, scope.equipmentCategoryColors).then(success, error);
				else if (scope.selectedTheme === 'doorLock')
					themeServices[scope.selectedTheme].getStatus(areas, scope.guidDrawing, scope.selectedDoorLocks, scope.doorLockColors).then(success, error);
				else
					themeServices[scope.selectedTheme].getStatus(areas, scope.guidDrawing).then(success, error);
				scope.filters.showAllStatuses = false;
			}

			function loadedSvg(svg) {
				var path, box, text, callsCompleted = 0;

				var defs = svg.defs('gradients');
				svg.linearGradient(defs, 'markerGradient', [[0, '#296375'], [0.7, '#184a5d'], [1, '#183b45']], '0%', '0%', '0%', '100%');

				areas = [];
				//get all areaguids and areaIds
				$(element[0].innerHTML).find("#world_coordinates g").each(function (index, area) {
					var areaId = $(area).attr('areaid');
					areas.push({ GuidArea: area.id, AreaId: areaId });
				});

				var worldCoordinates = $('#world_coordinates')[0];
				svg.group(worldCoordinates, 'periodicMarker-coordinates');
				svg.group(worldCoordinates, 'areaId-label-coordinates');
				svg.group(worldCoordinates, 'marker-coordinates');
				svg.group(worldCoordinates, 'doorlock-coordinates');

				getStatuses();
			}

			var dwgThemeService = $injector.get('DwgThemeService');
			scope.themeSelections = dwgThemeService.getAvailableThemes(scope.selectedTheme, scope.isStaticTheme);

			function getRandomColor() {
				var letters = '0123456789ABCDEF';
				var color = '#';
				for (var i = 0; i < 6; i++) {
					color += letters[Math.floor(Math.random() * 16)];
				}
				return color;
			}

			scope.selectEquipmentCategories = function () {
				themeServices.equipment.selectEquipmentCategories(scope.selectedEquipmentCategories).then(function (result) {
					result.forEach(function (newCategory) {
						if (!scope.equipmentCategoryColors[newCategory.Guid]) {
							if (equipmentPalette[0])
								scope.equipmentCategoryColors[newCategory.Guid] = equipmentPalette[0];
							else
								scope.equipmentCategoryColors[newCategory.Guid] = getRandomColor();
							equipmentPalette.splice(0, 1);
						}
						scope.selectedEquipmentCategories.push(newCategory);
					});
					getStatuses();
				});
			};

			scope.removeEquipmentCategory = function (index) {
				var removedCategory = scope.selectedEquipmentCategories.splice(index, 1)[0];
				equipmentPalette.push(scope.equipmentCategoryColors[removedCategory.Guid]);
				delete scope.equipmentCategoryColors[removedCategory.Guid];
				getStatuses();
			};

			scope.selectDoorLocks = function () {
				themeServices.doorLock.selectDoorLocks(scope.selectedDoorLocks, scope.guidDrawing).then(function (result) {
					result.forEach(function (newCategory) {
						if (!scope.doorLockColors[newCategory.Guid]) {
							if (doorLockPalette[0])
								scope.doorLockColors[newCategory.Guid] = doorLockPalette[0];
							else
								scope.doorLockColors[newCategory.Guid] = getRandomColor();
							doorLockPalette.splice(0, 1);
						}
						scope.selectedDoorLocks.push(newCategory);
					});
					getStatuses();
				});
			};

			scope.removeDoorLock = function (index) {
				var removed = scope.selectedDoorLocks.splice(index, 1)[0];
				doorLockPalette.push(scope.doorLockColors[removed.Guid]);
				delete scope.doorLockColors[removed.Guid];
				getStatuses();
			};

			var repository = $injector.get('Repository');

			var getTitle = function (result) {
				var title = '';
				if (result.Building) {
					if (result.Building.Estate) {
						title = '<i class="fas fa-city"></i> ' + result.Building.Estate.Caption + '<br />';
					}
					title += '<i class="fas fa-building-columns"></i> ' + result.Building.Caption + '<br />';
				}
				title += '<i class="fas fa-image"></i> ' + result.Id;

				return title;
			};

			var getDrawing = function () {
				if (!scope.guidDrawing) {
					if (document.getElementById('dwgDrawing')) {
						svgPanZoom('#dwgDrawing').destroy();
						$('#dwgDrawing').svg('destroy');
					}
					scope.title = "";
					return;
				}

				var columns = ['Svg', 'Id', 'Building.Id', 'Building.GuidEstate', 'Building.Description', 'Building.Estate.Id', 'Building.Estate.Description'];
				repository.getSingle(repository.apiData.drawing.url, scope.guidDrawing, JSON.stringify(columns)).then(function (result) {
					if (document.getElementById('dwgDrawing')) {
						svgPanZoom('#dwgDrawing').destroy();
						$('#dwgDrawing').svg('destroy');
					}

					scope.title = getTitle(result.Data);
					drawingGuidEstate = result.Data.Building ? result.Data.Building.GuidEstate : null;

					if (!result.Data.Svg) return;
					result = result.Data.Svg.replace('<svg ', '<svg id="dwgDrawing" ');
					result = result.replace('id="world_coordinates"', 'id="world_coordinates" class="planiaData"');
					element.find('#dwg')[0].innerHTML = result;
					$('#dwgDrawing').svg({ onLoad: loadedSvg });

					svgPanZoom('#dwgDrawing', {
						dblClickZoomEnabled: false,
						customEventsHandler: customEventHandler
					});

					if (typeof (CollectGarbage) === "function") // IE Force garbage collection
						CollectGarbage();
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};
			getDrawing();

			scope.toggleMultipleAreaSelect = function () {
				scope.selectMultipleAreas = !scope.selectMultipleAreas;
				selectAll = false;

				if (!scope.selectMultipleAreas) {
					selectedAreas.forEach(function (area) {
						var path = $('#' + area.guid).children()[0];
						$(path).attr('fill', area.originalColor);
						$(path).attr('fill-opacity', area.originalOpacity);
					});
					selectedAreas = [];
				}
			};

			scope.selectAllAreas = function () {
				selectAll = !selectAll;

				if (selectAll) {
					//select all
					_.forEach(areas, function (area) {
						if (!_.some(selectedAreas, { 'guid': area.GuidArea }))
							selectArea(area.GuidArea);
					});
				} else {
					_.forEach(areas, function(area) {
						if (_.some(selectedAreas, { 'guid': area.GuidArea }))
							selectArea(area.GuidArea);
					});
					// deselect all
				}
			};

			scope.handleMultipleAreas = function () {
				if (selectedAreas.length === 0 || (scope.selectedTheme !== 'cleaning' && scope.selectedTheme !== 'request' && scope.selectedTheme !== 'cleaningMaintenance')) return;

				var guids = selectedAreas.map(function (area) { return area.guid; });
				scope.shouldStopInterval = true;
				themeServices[scope.selectedTheme].handleMultipleAreas(guids).then(function (response) {
					if (response && response.update) {
						if (scope.selectMultipleAreas) scope.toggleMultipleAreaSelect();
						getStatuses();
					}
					scope.shouldStopInterval = false;
				}, function (error) {
					scope.shouldStopInterval = false;
				});
			};

			scope.getSelectMultipleAreaCaption = function () {
				if (scope.selectedTheme)
					return translationService.translate('web-dwg-selectMultipleAreas-tooltip-handle-' + scope.selectedTheme, 'Velg');
				return translationService.translate('web-dwg-selectMultipleAreas-tooltip-handle-default', 'Velg');
			};

			scope.editMultipleAreas = function() {
				if (selectedAreas.length === 0 || (scope.selectedTheme !== 'request')) return;

				var guids = selectedAreas.map(function(area) { return area.guid; });
				themeServices[scope.selectedTheme].editMultipleAreas(guids).then(function(response) {
						if (response && response.update) {
							if (scope.selectMultipleAreas) scope.toggleMultipleAreaSelect();
							getStatuses();
						}
						scope.shouldStopInterval = false;
					},
					function(error) {
						scope.shouldStopInterval = false;
					});
			};
		},
		templateUrl: '/app/dwg/views/dwgDirective.html'
	};
}]);
