(function () {
	angular.module('Plania').controller('BrandingController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', 'GeneralOptionsService', 'ListService', '$localStorage', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, generalOptionsService, listService, $localStorage) {
		$scope.generalOptions = angular.copy($localStorage.generalOptions);
		$scope.refreshId = new Date().toISOString();
		$scope.dataOwners = [];
		$scope.search = {};

		var getGeneralOptions = function (refreshImage) {
			generalOptionsService.getGeneralOptionsAsync(true).then(function (generalOptions) {
				$scope.generalOptions = angular.copy(generalOptions);
				if (refreshImage)
					$scope.refreshId = new Date().toISOString();
			});
		};
		getGeneralOptions();

		$scope.onImageFileSelect = function (images, isLogo) {
			var files = [];
			if (!images || images.length === 0) return;

			handleFileUpload(images[0]);
			//Read Base64 string and add to file object
			function handleFileUpload(file) {
				var reader = new FileReader();
				reader.onload = function (e) {
					var newFile = {
						ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
						Name: file.name
					};
					files.push(newFile);
					onLoadEndHandler();
				};
				reader.readAsDataURL(file);
			}

			function onLoadEndHandler(image) {
				var url = repository.apiData.generalOptions.url + "backgroundImage";
				if (isLogo)
					url = repository.apiData.generalOptions.url + "logoImage";

				repository.createMultiple(url, files).then(function (result) {
					var successMessage = isLogo ?
						translationService.translate('web-branding-imageLogo-update-success', 'Logo har blitt oppdatert') :
						translationService.translate('web-branding-imageBackground-update-success', 'Bakgrunnsbilde har blitt oppdatert');
					repository.growl(successMessage, 'success');

					getGeneralOptions(true);
				}, function (error) {
					if (error)
						repository.growl(error, 'danger');
				});
			}
		};

		$scope.deleteImage = function (isLogo) {
			var url = repository.apiData.generalOptions.url + "backgroundImage/";
			if (isLogo)
				url = repository.apiData.generalOptions.url + "logoImage/";

			repository.deleteSingle(url, "").then(function (result) {
				var successMessage = isLogo ?
					translationService.translate('web-branding-imageLogo-delete-success', 'Logo har blitt slettet') :
					translationService.translate('web-branding-imageBackground-delete-success', 'Bakgrunnsbilde har blitt nullstilt');
				repository.growl(successMessage, 'success');

				getGeneralOptions(true);
			}, function (error) {
				repository.growl(error);
			});
		};

		$scope.dataOwnerTable = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: "asc" }
		}, {
			total: 0,
			counts: [10, 25, 50],
			filterDelay: 50,
			paginationMaxBlocks: 6,
			getData: function ($defer, params) {
				if (!$scope.hasReadAccess('DataOwner')) return;
				var columns = ["Id", "Description"];


				repository.GetPaginated(repository.apiData.dataOwner.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
					$scope.dataOwnerTable.settings().total = result.TotalCount;
					$scope.dataOwnerTable.settings().filterDelay = 500;
					$scope.dataOwners = result.List;
					$defer.resolve(result.List);
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}
		});

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			if (icon)
				htmlText += '<i class="fas' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.dataOwnerContextMenu = function (dataOwner) {
			var options = [];
			options.push(getMenuLink($scope.navigation.href('dataOwner.edit', { guid: dataOwner.Guid }), translationService.translate('web-branding-dataOwnerContextMenu-viewDataOwner', 'Gå til klient')));
			return options;
		};
	}
})();
