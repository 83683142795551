// Directive to show/hide a container depending on supplied fieldrules.
(function () {
	angular.module('Plania').directive('plFieldruleVisbility', ['$animate', '$localStorage', 'RegisteredFieldService', 'Repository', function ($animate, $localStorage, registeredFieldService, repository) {
		return {
			restrict: 'A',
			scope: {
				fieldRules: '=plFieldruleVisbility', // List of rules. ex. ['WorkOrder.Id', 'WorkOrder.EndDate']
				plFieldRuleMode: '=plFieldRuleMode', // Any | All - Default to Any
			},
			link: function (scope, element, attrs) {
				var isVisible = function (fieldRule) {
					if (fieldRule.Hidden || (fieldRule.ExternallyHidden && $localStorage.userData.isExternalUser)) {
						return false;
					}
					return true;
				};

				var applyVisibilityRules = function (visibleRules) {
					if (scope.plFieldRuleMode && scope.plFieldRuleMode.toLowerCase() === 'all') {
						if (_.every(visibleRules)) {
							if (element.hasClass('ng-hide'))
								$animate.removeClass(element, 'ng-hide');
						} else {
							$animate.addClass(element, 'ng-hide');
						}
					} else { // Any
						if (_.some(visibleRules)) {
							if (element.hasClass('ng-hide'))
								$animate.removeClass(element, 'ng-hide');
						} else {
							$animate.addClass(element, 'ng-hide');
						}
					}
				};

				var checkFieldrule = function () {
					if (!scope.fieldRules || scope.fieldRules.length === 0) return;

					var visibleRules = [];
					var nrOfRuns = 0; // Helper to check when all async calls are done.

					scope.fieldRules.forEach(function (fieldRule) {
						registeredFieldService.getFieldRuleByEntityAndColumn(fieldRule).then(function (fieldRule) {
							nrOfRuns++;
							var visible = isVisible(scope.onGetValidityRules ? scope.onGetValidityRules(fieldRule) : fieldRule);
							visibleRules.push(visible);
							if (nrOfRuns === scope.fieldRules.length)
								applyVisibilityRules(visibleRules);
						}, function (error) {
							nrOfRuns++;
							if (nrOfRuns === scope.fieldRules.length)
								applyVisibilityRules(visibleRules);
							repository.showError(error);
						});
					});
				};

				scope.$watch('$parent.viewMode', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					checkFieldrule();
				});

				scope.$watch('$parent.modelDto.dataOwner', function (newValue, oldValue) {
					if (newValue === oldValue) return;
					checkFieldrule();
				});

				checkFieldrule();
			},
		};
		}
	]);
})();
