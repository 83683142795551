(function () {
	angular
		.module("Plania")
		.controller("BimFileController", [
			"$scope",
			"$stateParams",
			"Repository",
			"TranslationService",
			"$modal",
			"$rootScope",
			"ngTableParams",
			"$filter",
			"$window",
			"config",
			"ListService",
			controller,
		]);

	function controller(
		$scope,
		$stateParams,
		repository,
		translationService,
		$modal,
		$rootScope,
		ngTableParams,
		$filter,
		$window,
		config,
		listService
	) {
		$scope.isUpdate = $scope.navigation.current.name === "bimFile.edit";
		$scope.activateAutocomplete = false;
		$scope.model = { Guid: $stateParams.guid };
		$scope.viewMode = $scope.isUpdate ? true : false;

		$scope.bimCache = {};

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
		};

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO =
					$scope.model.GuidDataOwner !==
					repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(
					repository.commonService.prefix.BimFile,
					checkOtherDO
				);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(
					repository.commonService.prefix.BimFile,
					checkOtherDO
				);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(
					repository.commonService.prefix.BimFile
				);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(
					repository.commonService.prefix.BimFile
				);
			}
		};

		updateAccess();

		var setProperties = function () {
			var bimFile = $scope.model;

			if (bimFile.Building)
				$scope.building = $scope.getEntityCaption(bimFile.Building);
		};

		if ($scope.isUpdate) {
			getModel();
		} else {
			$scope.activateAutocomplete = true;
		}

		function getModel() {
			return repository
				.getSingle(
					repository.apiData.bimFile.url,
					$stateParams.guid
				)
				.then(
					function (response) {
						$scope.model = response.Data;

						updateAccess();
						setProperties();
						setTimeout(function () {
							$scope.activateAutocomplete = true;
						}, 250);
					},
					function (error) {
						repository.growl(error, "danger");
					}
				);
		}

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl(
					translationService.translate(
						"web-bimFile-update-success",
						"Bim filen har blitt oppdatert"
					),
					"success"
				);
				$scope.goBack(destination, {
					guid: $scope.model.Guid,
					menuGuid: $scope.navigation.params.menuGuid,
				});
			};

			var error = function (error) {
				if (typeof error === "string") {
					repository.growl(error, "danger");
				} else {
					repository.growl(error.Data.Message, "danger");
				}
			};

			if ($scope.isUpdate) {
				repository
					.updateSingle(repository.apiData.bimFile.url, $scope.model)
					.then(success)
					.catch(error);
			}
		};

		$scope.cancel = function () {
			$scope.goBack("bimFile.list", {
				menuGuid: $scope.navigation.params.menuGuid,
			});
		};

		$scope.delete = function () {
			swal(
				{
					title: translationService.translate(
						"web-swal-error-areyousure",
						"Er du sikker?"
					),
					text: translationService.translate(
						"web-swal-bimFile-message",
						"BIM-filen vil bli permanent fjernet!"
					),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate(
						"web-swal-bimFile-button-confirm",
						"Ja, fjern BIM-filen"
					),
					cancelButtonText: translationService.translate(
						"web-button-cancel",
						"Avbryt"
					),
					closeOnConfirm: false,
					showLoaderOnConfirm: true,
				},
				function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository
						.deleteSingle(repository.apiData.bimFile.url, $scope.model.Guid)
						.then(function (result) {
							swal(
								translationService.translate(
									"web-swal-bimFile-success",
									"BIM-filen ble fjernet!"
								),
								result,
								"success"
							);
							$scope.goBack("bimFile.list", {
								menuGuid: $scope.navigation.params.menuGuid,
							});
						});
				}
			);
		};

		$scope.createNewRevision = function () {
			$modal
				.open({
					templateUrl: "app/bimFile/views/createBimFileModal.html",
					controller: "CreateBimFileModalController",
					resolve: {
						modalParams: function () {
							return {
								model: $scope.model,
							};
						},
					},
				})
				.result.then(function () {
					$scope.goBack("bimFile.edit", {
						menuGuid: $scope.navigation.params.menuGuid,
					});
					repository.growl(
						translationService.translate(
							"web-bimFile-createNewRevision-started",
							"Ny revisjon er opprettet, og vil bli tilgjengelig når filen er ferdig prossessert."
						),
						"success"
					);
				});
		};

		$scope.$on($scope.events.newSelection, function () {
			getModel();
			updateAccess();
		});


		var revisions = [];
		$scope.revisionColumns = [
			{
				Position: 1,
				Title: translationService.translate(
					"web-bimFile-revision-version",
					"Modellserver versjonsnummer"
				),
				Property: "version",
				PropertyType: "number",
			},
			{
				Position: 2,
				Title: translationService.translate(
					"web-bimFile-revision-createdAt",
					"Opplastet"
				),
				Property: "createdAt",
				PropertyType: "date",
			},
			{
				Position: 3,
				Title: translationService.translate(
					"web-bimFile-revision-comment",
					"Revisjonskommentar"
				),
				Property: "comment",
				PropertyType: "string",
			},
		];

		function getRevisions() {
			repository
				.getSingleWithNoParameter(
					repository.apiData.bimFile.url + $scope.model.Guid + "/GetRevisions"
				)
				.then(function (result) {
					revisions = result;
					$scope.revisionsTable.reload();
				});
		}
		getRevisions();

		var sortFunction = function (key) {
			return function (a, b) {
				var aVal = a[key];
				var bVal = b[key];

				if (aVal < bVal) {
					return -1;
				}
				if (aVal > bVal) {
					return 1;
				}
				return 0;
			};
		};

		var getFilteredData = function (filter) {
			var filterText = filter.searchString.toLowerCase();
			var data = revisions;

			data = data.filter(function (product) {
				if (
					filterText === undefined ||
					filterText === null ||
					filterText.trim() === ""
				)
					return true;

				var state = false;

				$scope.revisionColumns.forEach(function (column) {
					var value = product[column.Property];
					if (("" + value).toLowerCase().includes(filterText)) state = true;
					if (state) return;
				});

				return state;
			});

			return data;
		};

		$scope.getFormattedDate = function (date) {
			return $filter("date")(date, "dd.MM.yyyy, HH:mm:ss");
		};

		$scope.getPropertyValue = function (revision, column) {
			var value = revision[column.Property];
			if (value === undefined || value === null || value === "") return "";

			if (column.PropertyType === "date")
				return $filter("date")(value, "dd.MM.yyyy, HH:mm:ss");

			var result = $filter("limitTo")(value, 50);
			if (value.length > 50) {
				return result + "...";
			} else {
				return result;
			}
		};

		$scope.revisionsTable = new ngTableParams(
			{
				page: 1,
				count: 10,
				sorting: { version: "desc" },
				filter: { searchString: "" },
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var data = getFilteredData(params.filter());

					var sortingKey = Object.keys(params.sorting())[0];
					var direction = params.sorting()[sortingKey];
					data = data.sort(sortFunction(sortingKey));
					if (direction.toLowerCase() === "desc") data = data.reverse();

					$scope.revisionsTable.settings().total = data.length;
					$scope.revisionsTable.settings().filterDelay = 50;

					data = data.slice(
						(params.page() - 1) * params.count(),
						params.page() * params.count()
					);
					$defer.resolve(data);
				},
			}
		);

		$scope.resetCacheData = function () {
			$scope.model.ProcessingStatus = "Internal";
		};

		$scope.showBimFileCacheStatusModal = function () {
			$modal
				.open({
					templateUrl: "app/bimFile/views/bimFileCacheStatusModal.html",
					controller: "BimFileCacheStatusModalController",
					resolve: {
						params: function () {
							return {
								GuidBimFile: $scope.model.Guid,
								resetCacheData: $scope.resetCacheData,
								getModel: getModel,
								ProcessingStatus: $scope.model.ProcessingStatus,
								ProcessingError: $scope.model.ProcessingError,
								RevisionComment: $scope.model.RevisionComment,
							};
						},
					},
				})
				.result.then(
					function () { },
					function () {
						//cancel
					}
				);
		};

		$scope.open2DMappingModal = function () {
			$modal
				.open({
					templateUrl: "app/bimFile/views/bimFileMappingModal.html",
					controller: "bimFileMappingModalController",
					size: 'lg',
					resolve: {
						params: function () {
							return {
								GuidBimFile: $scope.model.Guid,
								getModel: getModel,
								MappingData: $scope.model.MappingData,
							};
						},
					},
				})
				.result.then(
					function () {
						getModel();
					},
					function () {
						//cancel
					}
				);
		};

		$scope.getRevisionOptions = function (revision) {
			var options = [];

			options.push({
				html:
					'<a class="dropdown-item" tabindex= "-1" href=""><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' +
					translationService.translate(
						"web-bimFile-revision-download",
						"Last ned"
					) +
					"</a >",
				click: function ($itemScope, $event, modelValue, text, $li) {
					repository
						.getSingleWithNoParameter(
							repository.apiData.bimFile.url +
							$scope.model.Guid +
							"/GetRevisionDownloadToken/" +
							revision.id
						)
						.then(
							function (token) {
								var dataOwner =
									repository.commonService.getFilterData().selectedDataOwner
										.Guid;
								$window.open(
									config.baseUrlApi +
									repository.apiData.bimFile.url +
									$scope.model.Guid +
									"/DownloadRevision/" +
									revision.id +
									"?dataOwner=" +
									dataOwner +
									"&token=" +
									token,
									"_blank"
								);
							},
							function (error) {
								repository.showError(error);
							}
						);
				},
			});

			return options;
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="fas' + icon + ' fa-hc-fw fa-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="fas fa-suitcase fa-hc-fw fa-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.getBuildingXBimFileOptions = function (buildingXBimFile) {
			var options = [];

			var toggleText = buildingXBimFile.ShowAsDefault ?
				translationService.translate('web-bimFile-buildingXBimFile-showAsDefault-false', 'Ikke vis modell som standard ved åpning') :
				translationService.translate('web-bimFile-buildingXBimFile-showAsDefault-true', 'Vis modell som standard ved åpning');

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + toggleText + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					repository.patch(repository.apiData.buildingXBimFile.url, buildingXBimFile.Guid, { ShowAsDefault: !buildingXBimFile.ShowAsDefault }).then(function (result) {
						$scope.buildingXBimFileTable.reload();
					}, function (error) {
						repository.showError(error);
					});
				}
			});

			options.push(getMenuLink($rootScope.navigation.href('bimFile.viewBim', { guidBuilding: buildingXBimFile.GuidBuilding, guidBimFile: $scope.model.Guid }), translationService.translate('web-bimFile-buildingXBimFile-viewInBimsyncViewer', 'Gå til bim modell')));

			if ($scope.hasReadAccess('Building'))
				options.push(getMenuLink($rootScope.navigation.href('building.edit', { guid: buildingXBimFile.GuidBuilding }), translationService.translate('web-bimFile-buildingXBimFile-viewBuilding', 'Gå til bygg')));

			if ($scope.hasDeleteAccess('BuildingXBimFile')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="fas  fa-pen-to-square fa-hc-fw fa-hc-lg"></i> ' + translationService.translate('web-bimFile-buildingXBimFile-delete', 'Knytt bort fra bygg') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						swal({
							title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
							text: translationService.translate('web-swal-buildingXBimFile-message', 'Knytningen immot bygg vil bli fjernet.'),
							type: "warning",
							showCancelButton: true,
							confirmButtonColor: "#f44336",
							confirmButtonText: translationService.translate('web-swal-buildingXBimFile-button-confirm', 'Ja'),
							cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
							closeOnConfirm: false,
							showLoaderOnConfirm: true
						}, function () {
							window.onkeydown = null;
							window.onfocus = null;

							repository.deleteSingle(repository.apiData.buildingXBimFile.url, buildingXBimFile.Guid).then(function () {
								swal.close();
								$scope.buildingXBimFileTable.reload();
							}, function (error) {
								repository.showError(error);
							});
						});

					}
				});
			}
			return options;
		};

		$scope.getPropertyValue = function (entity, field) {
			return listService.GetPropertyValue(entity, field);
		};

		$scope.buildingXBimFileColumns = [
			{ Position: 1, Title: "Bygg", Property: "Building.Id", PropertyType: "string" },
			{ Position: 2, Title: "Bygg", Property: "Building.Description", PropertyType: "string" },
			{ Position: 3, Title: "Kategori", Property: "Building.BuildingCategory.Description", PropertyType: "string" },
			{ Position: 4, Title: "Vis som standard", Property: "ShowAsDefault", PropertyType: "bool" }
		];

		$scope.buildingXBimFileTable = new ngTableParams(
			{
				page: 1,
				count: 10,
				sorting: { "Building.Id": "asc" },
				filter: {
					searchString: "",
					FilterModel: {
						Condition: "and",
						Rules: [
							{
								Property: "GuidBimFile",
								Operator: "=",
								Value: $stateParams.guid
							}
						]
					}
				}
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var columns = [];
					$scope.buildingXBimFileColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.buildingXBimFile.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns)).then(function (result) {
						$scope.buildingXBimFileTable.settings().total = result.TotalCount;
						$scope.buildingXBimFileTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					});
				},
			}
		);

		$scope.addNewBuilding = function () {
			$modal.open({
				templateUrl: "app/bimFile/views/createBuildingXBimFileModal.html",
				controller: "CreateBuildingXBimFileModalController",
				resolve: {
					params: function () {
						return {
							guidBimFile: $scope.model.Guid,
							guidBimProject: $scope.model.GuidBimProject
						};
					},
				},
			}).result.then(function () {
				$scope.buildingXBimFileTable.reload();
			}, function () {/*cancel*/ });
		};
	}
})();
