(function () {
	angular.module('Plania').controller('CreateDocumentXEntityController', ['$scope', 'Repository', '$modalInstance', 'params', '$filter', 'TranslationService', controller]);

	function controller($scope, repository, $modalInstance, params, $filter, translationService) {
		$scope.isLoading = false;
		$scope.createDocumentXEntity = true;
		$scope.isModal = true; //this controller is only invoked from modals
		$scope.model = {};
		$scope.model.GuidArea = params.guidArea || '';
		$scope.model.GuidBuilding = params.guidBuilding || '';
		$scope.model.GuidCleaningTask = params.guidCleaningTask || '';
		$scope.model.GuidComponent = params.guidComponent || '';
		$scope.model.GuidCondition = params.guidCondition || '';
		$scope.model.GuidDeviation = params.guidDeviation || '';
		$scope.model.GuidCustomer = params.guidCustomer || '';
		$scope.model.GuidEquipment = params.guidEquipment || '';
		$scope.model.GuidEstate = params.guidEstate || '';
		$scope.model.GuidPeriodicTask = params.guidPeriodicTask || '';
		$scope.model.GuidPurchaseOrder = params.guidPurchaseOrder || '';
		$scope.model.GuidRequest = params.guidRequest || '';
		$scope.model.GuidWorkOrder = params.guidWorkOrder || '';
		$scope.model.GuidSupplier = params.guidSupplier || '';
		$scope.model.GuidCost = params.guidCost || '';
		$scope.model.GuidPaymentOrder = params.guidPaymentOrder || '';
		$scope.model.GuidContract = params.guidContract || '';
		$scope.model.GuidControlListXEntity = params.guidControlListXEntity || '';
		$scope.model.GuidControlListItemAnswer = params.guidControlListItemAnswer || '';
		$scope.model.GuidProject = params.guidProject || '';
		$scope.model.GuidDoorKey = params.guidDoorKey || '';
		$scope.model.GuidDoorLock = params.guidDoorLock || '';


		if (params.controlListItemAnswers) {
			$scope.groupedControlListItemAnswers = [];
			var currentSection = null;
			params.controlListItemAnswers.forEach(function (controlListItemAnswer) {
				if (controlListItemAnswer.ControlListItem.Type === 'SectionHeader') {
					currentSection = controlListItemAnswer.ControlListItem.Name;
				} else if (controlListItemAnswer.ControlListItem.Type !== 'Info') {
					if (currentSection) controlListItemAnswer.Section = currentSection;
					$scope.groupedControlListItemAnswers.push(controlListItemAnswer);
				}
			});
		}

		$scope.checkbox = {
			isRegulatoryCompilanceDocumentation: false,
			isAttestationDocumentation: false,
			isDescriptionDocumentation: false,
			isOperationDocumentation: false,
			isMaintenanceDocumentation: false,
			isProductInfoDocumentation: false,
			isInstructionDocumentation: false,
			isProtocolDocumentation: false,
			isIdentificationDocumentation: false,
			isDrawingDocumentation: false,
			isImageDocumentation: false,
		};

		var getCheckbox = function () {
			var checkbox = 0;
			if ($scope.checkbox.isRegulatoryCompilanceDocumentation)
				checkbox = checkbox | 1 << 0;
			if ($scope.checkbox.isAttestationDocumentation)
				checkbox = checkbox | 1 << 1;
			if ($scope.checkbox.isDescriptionDocumentation)
				checkbox = checkbox | 1 << 2;
			if ($scope.checkbox.isOperationDocumentation)
				checkbox = checkbox | 1 << 3;
			if ($scope.checkbox.isMaintenanceDocumentation)
				checkbox = checkbox | 1 << 4;
			if ($scope.checkbox.isProductInfoDocumentation)
				checkbox = checkbox | 1 << 5;
			if ($scope.checkbox.isInstructionDocumentation)
				checkbox = checkbox | 1 << 6;
			if ($scope.checkbox.isProtocolDocumentation)
				checkbox = checkbox | 1 << 7;
			if ($scope.checkbox.isIdentificationDocumentation)
				checkbox = checkbox | 1 << 8;
			if ($scope.checkbox.isDrawingDocumentation)
				checkbox = checkbox | 1 << 9;
			if ($scope.checkbox.isImageDocumentation)
				checkbox = checkbox | 1 << 10;

			return checkbox;
		};


		$scope.isDynamicPropertiesCollapsed = false;

		var selectedDataOwner = repository.commonService.getFilterData().selectedDataOwner;
		if (selectedDataOwner.GuidDefaultDocumentType) {
			$scope.model.DocumentType = { Guid: selectedDataOwner.GuidDefaultDocumentType, Caption: selectedDataOwner.DefaultDocumentTypeId };
			$scope.model.GuidDocumentType = selectedDataOwner.GuidDefaultDocumentType;
		}

		if (params.defaultCategory) {
			$scope.model.GuidDocumentCategory = params.defaultCategory.Guid || '';
			$scope.model.DocumentCategory = { Guid: $scope.model.GuidDocumentCategory, Caption: params.defaultCategory.Name };

			if ($scope.model.GuidDocumentCategory) {
				repository.getSingle(repository.apiData.documentCategory.url, $scope.model.GuidDocumentCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}
		}
		$scope.files = [];


		$scope.onFileSelect = function ($files) {
			$scope.files = $scope.files.concat($files);
		};

		$scope.dragOverClass = function ($event) {
			var items = $event.dataTransfer.items;
			var hasFile = false;
			if (items) {
				for (var i = 0; i < items.length; i++) {
					if (items[i].kind === 'file') {
						hasFile = true;
						break;
					}
				}
			} else {
				hasFile = true;
			}
			return hasFile ? "drop-box-dragover" : "";
		};

		$scope.filesizeToLarge = false;

		$scope.removeFile = function (index) {
			$scope.files.splice(index, 1);
		};

		var addGuidToFile = function (file) {
			file.GuidArea = params.guidArea || '';
			file.GuidBuilding = params.guidBuilding || '';
			file.GuidCleaningTask = params.guidCleaningTask || '';
			file.GuidComponent = params.guidComponent || '';
			file.GuidCondition = params.guidCondition || '';
			file.GuidDeviation = params.guidDeviation || '';
			file.GuidCustomer = params.guidCustomer || '';
			file.GuidEquipment = params.guidEquipment || '';
			file.GuidEstate = params.guidEstate || '';
			file.GuidPeriodicTask = params.guidPeriodicTask || '';
			file.GuidPurchaseOrder = params.guidPurchaseOrder || '';
			file.GuidRequest = params.guidRequest || '';
			file.GuidWorkOrder = params.guidWorkOrder || '';
			file.GuidSupplier = params.guidSupplier || '';
			file.GuidCost = params.guidCost || '';
			file.GuidPaymentOrder = params.guidPaymentOrder || '';
			file.GuidContract = params.guidContract || '';
			file.GuidControlListXEntity = params.guidControlListXEntity || '';
			file.GuidProject = params.guidProject || '';
			file.GuidDoorKey = params.guidDoorKey || '';
			file.GuidDoorLock = params.guidDoorLock || '';
		};

		$scope.uploadImage = function () {

			if ($scope.files.length < 1) {
				swal(translationService.translate('web-image-error-noFiles', 'Ingen bilder valgt'), translationService.translate('web-image-error-noimagesdescription', 'Du må velge et eller flere bilder for å lagre'), 'info');
				return;
			}

			var totalSize = 0;
			$scope.files.forEach(function (file) {
				totalSize += file.size;
			});

			if (totalSize / 1048576 > 40) {
				var totalSizeMessage = translationService.translate('web-image-upload-tooLarge-message', 'Du kan ikke laste opp mer enn 40 MB om gangen. Nåværende filstørrelse:');
				totalSizeMessage += ' ' + $filter('number')(totalSize / 1048576, 2) + ' MB';
				swal(
					translationService.translate('web-image-upload-tooLarge-title', 'For stor filstørrelse'),
					totalSizeMessage,
					'warning'
				);
				return;
			}

			var totalFiles = $scope.files.length;
			var processedCount = 0;
			var files = [];

			for (var i = 0; i < $scope.files.length; i++) {
				(handleFileUpload($scope.files[i]));
			}

			//Read Base64 string and add to file object
			function handleFileUpload(file) {
				var reader = new FileReader();
				reader.onload = function (e) {
					var newFile = {
						ImageSrc: e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length),
						Name: file.name,
						Description: $scope.model.Description ? $scope.model.Description.substring(0, 255) : file.name
					};

					if ($scope.model.GuidControlListItemAnswer)
						newFile.GuidControlListItemAnswer = $scope.model.GuidControlListItemAnswer;

					addGuidToFile(newFile);
					files.push(newFile);
					onLoadEndHandler();
				};
				reader.readAsDataURL(file);
			}

			function onLoadEndHandler() {
				processedCount++;
				if (processedCount === totalFiles) {
					repository.createMultiple(repository.apiData.image.url, files).then(function (result) {
						if ($scope.model.GuidControlListItemAnswer) {
							result.forEach(function (o) {
								o.GuidControlListItemAnswer = $scope.model.GuidControlListItemAnswer;
							});
						}

						if ($scope.model.GuidControlListXEntity) {
							result.forEach(function (o) {
								o.GuidControlListXEntity = $scope.model.GuidControlListXEntity;
							});
						}

						$scope.ok(result);
					}, function (error) {
						repository.growl(error);
					});
				}
			}
		};

		var getFilesToUpload = function (allFiles) {
			var files = _.reject(allFiles, function (file) {
				// If retrying upload, remove the files already uploaded
				return file.progressType === 'success';
			});

			files.forEach(function (file) {
				// Generate unique id for files used for upload progress update
				file.id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
				file.count = 0;
				file.progressType = null;
			});
			return files;
		};

		var hasAllFilesBeenUploaded = function (allFiles) {
			// Remove all successfull uploads
			var files = _.reject(allFiles, function (file) {
				return file.progressType === 'success';
			});

			// 0 results means that all has been uploaded.
			return !files.length;
		};

		$scope.uploadDocument = function () {
			if ($scope.isUploading) return;

			if ($scope.files.length < 1 && !$scope.model.IsExternalLink) {
				swal(translationService.translate('web-document-error-noFiles', 'Ingen filer valgt'), translationService.translate('web-document-error-nofilesdescription', 'Du må velge en eller flere filer for å lagre'), 'info');
				return;
			}

			var filesToUpload = getFilesToUpload($scope.files);
			$scope.model.DocumentationContext = getCheckbox();

			if ($scope.model.IsExternalLink && $scope.model.FilePath.indexOf('http') !== 0) {
				$scope.model.FilePath = 'https://' + $scope.model.FilePath;
			}

			$scope.isUploading = true;
			$scope.model.prefix = repository.apiData.document.prefix;

			repository.createSingleWithBinary(repository.apiData.document.urlChunk, $scope.model, filesToUpload).then(function (result) {
				repository.growl(translationService.translate('web-document-upload-success', 'Dokumentet har blitt lagret'), 'success');

				$scope.isUploading = false;
				$scope.alreadyUploaded = true;

				var allFilesUploaded = hasAllFilesBeenUploaded($scope.files);
				if (allFilesUploaded)
					$scope.ok(result);
			}, function (errors) {
				$scope.isUploading = false;

				if (Array.isArray(errors)) {
					repository.growl(errors[0].error, 'danger');
					errors.forEach(function (error) {
						var identifiedFile = _.find($scope.files, function (f) { return f.id === error.id; });
						if (identifiedFile) {
							identifiedFile.progressType = 'danger';
						}
					});
				} else {
					repository.growl(errors, 'danger');
				}
			}, function (file) { //UPDATE
				var identifiedFile = _.find($scope.files, function (f) { return f.id === file.id; });
				if (identifiedFile) {
					identifiedFile.total = file.total;
					identifiedFile.count = file.count;
					identifiedFile.progressType = file.count >= file.total ? 'success' : 'info';
				}
			});
		};


		$scope.onDocumentCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidDocumentCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidDocumentCategory = category.Guid;
		};

		$scope.ok = function (result) {
			$modalInstance.close(result);
		};

		var getUploadedFiles = function () {
			return _.filter($scope.files,
				function (file) {
					return file.progressType === 'success';
				});
		};

		$scope.cancel = function () {
			var files = getUploadedFiles();
			if (files && files.length > 0) {
				$scope.ok(files);
			}
			$modalInstance.dismiss('canceled');
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "documentCategory":
					filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
					return filter;
			}
			return filter;
		};
	}
})();
