(function () {
	angular.module('Plania').controller('BuildingSelectionController', ['$scope', 'Repository', '$stateParams', 'TranslationService', 'ngTableParams', '$modal', 'ListService', '$q', controller]);

	function controller($scope, repository, $stateParams, translationService, ngTableParams, $modal, listService, $q) {
		$scope.isUpdate = $scope.navigation.current.name === 'buildingSelection.edit';
		$scope.isDynamicPropertiesCollapsed = false;
		$scope.model = { Guid: $stateParams.guid };

		if ($scope.isUpdate) {
			repository.getSingle(repository.apiData.buildingSelection.url, $stateParams.guid).then(function (response) {
				$scope.reload = true;
				$scope.model = response.Data;
				$scope.reloadEntityAttributes = true;
			}, function (error) {
				repository.growl(error, 'danger');
			});
		}

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.update = function (destination) {
			var success = function (result) {
				repository.growl($scope.isUpdate ? translationService.translate('update-buildingSelection-success', 'Utvalg har blitt oppdatert.') : translationService.translate('create-buildingSelection-success', 'Utvalg har blitt opprettet.'), 'success');
				var guid = $scope.isUpdate ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				repository.showError(error);
			};

			if ($scope.isUpdate) {
				repository.updateSingle(repository.apiData.buildingSelection.url, $scope.model).then(success, error);
			} else {
				repository.createSingle(repository.apiData.buildingSelection.url, $scope.model).then(success, error);
			}
		};

		$scope.delete = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-buildingSelection-message', "Utvalg vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-buildingSelection-button-confirm', 'Ja, fjern utvalg'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.buildingSelection.url, $scope.model.Guid).then(function (result) {
					swal(translationService.translate('web-swal-buildingSelection-success', 'Utvalg ble fjernet!'), result, "success");
					$scope.goBack('buildingSelection.list', { menuGuid: $scope.navigation.params.menuGuid });
				});
			});
		};

		$scope.buildingXBuildingSelectionColumns = [
			{ Position: 1, Title: translationService.translate('web-building-id'), Property: 'Building.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-building-Description'), Property: 'Building.Description', PropertyType: 'autocomplete' }
		];

		$scope.buildingXBuildingSelectionTable = new ngTableParams(
			{
				page: 1,
				count: 100,
				sorting: { 'Building.Id': 'asc' },
				filter: { PropertyFilter: [{ Property: 'GuidBuildingSelection', Operator: '=', Value: $stateParams.guid }] }
			},
			{
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				paginationMaxBlocks: 6,
				getData: function ($defer, params) {
					var columns = [];
					$scope.buildingXBuildingSelectionColumns.forEach(function (col) {
						columns.push(col.Property);
					});

					repository.GetPaginated(repository.apiData.buildingBuildingSelection.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns)).then(function (result) {
						$scope.buildingXBuildingSelectionTable.settings().total = result.TotalCount;
						$scope.buildingXBuildingSelectionTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});

		var createBuildingXBuildingSelection = function (buildingXEntity) {
			var deferred = $q.defer();

			repository.createSingle(repository.apiData.buildingBuildingSelection.url, buildingXEntity).then(function () {
				deferred.resolve();
			}, function (error) {
				deferred.reject();
				repository.growl(error, 'danger');
			});

			return deferred.promise;
		};

		$scope.addBuildings = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				size: "lg",
				resolve: {
					modalParams: function () {
						return {
							title: translationService.translate('web-buildingSelection-addBuilding-title', 'Legg til Byggning'),
							columns: [
								{ Title: translationService.translate('web-estate-id'), Property: 'Estate.Id' },
								{ Title: translationService.translate('web-estate-Description'), Property: 'Estate.Description' },
								{ Title: translationService.translate('web-building-Id'), Property: 'Id' },
								{ Title: translationService.translate('web-building-Description'), Property: 'Description' },
								{ Title: translationService.translate('web-building-buildingCategory'), Property: 'BuildingCategory.Description' },
							],
							url: repository.apiData.building.url,
							sorting: { Id: 'asc' },
							filter: {
								FilterModel: {
									Condition: "and",
									Rules: [{
										Property: "BuildingXBuildingSelections.GuidBuildingSelection",
										Operator: "<>",
										Value: $scope.model.Guid,
										CollectionOperator: "all"
									}]
								}
							}
						};
					}
				}
			}).result.then(function (selectedGuids) {
				var promises = selectedGuids.map(function (guid) {
					return createBuildingXBuildingSelection({ GuidBuildingSelection: $stateParams.guid, GuidBuilding: guid });
				});

				$q.all(promises).then(function () {
					repository.growl("Byggningene har blitt lagt til", 'success');
					$scope.buildingXBuildingSelectionTable.reload();
				}).catch(function (error) {
					$scope.buildingXBuildingSelectionTable.reload();
				});
			});
		};

		$scope.deleteBuilding = function (building) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-buildingSelection-xEntity-message', "Byggning vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-buildingSelection-xEntity-button-confirm', 'Ja, fjern byggning'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.buildingBuildingSelection.url, building.Guid).then(function (result) {
					swal(translationService.translate('web-swal-buildigSelection-deleteBuilding-success', 'Byggning ble fjernet!'), result, "success");
					$scope.buildingXBuildingSelectionTable.reload();
				}, function (error) {
					repository.growl(error, 'danger');
					swal("Error!", error, "error");
				});
			});
		};
	}
})();
