(function () {
	angular.module('Plania').factory('OperationalMessageService', ['Repository', 'TranslationService', '$filter', '$rootScope', operationalMessageService]);

	function operationalMessageService(repository, translationService, $filter, $rootScope) {
		var service = {};

		service.getCommonFilterTypes = function () {
			return [
				{ value: 'active', name: translationService.translate('web-operationalMessage-filterType-active', 'Aktive') },
				{ value: 'planned', name: translationService.translate('web-operationalMessage-filterType-planned', 'Planlagt') },
				{ value: 'history', name: translationService.translate('web-operationalMessage-filterType-history', 'Historiske') },
				{ value: 'all', name: translationService.translate('web-operationalMessage-filterType-all', 'Alle') }
			];
		};

		// 'all' | 'active' | 'planned' | 'history'
		service.getCommonFilterModel = function (filterType) {
			var filterModel = {
				Condition: "and",
				Rules: []
			};

			switch (filterType) {
				case 'active':
					filterModel.Rules.push({ Property: 'StartDate', Operator: '<=', Value: moment().startOf('day').toISOString() });
					filterModel.Rules.push({
						Condition: 'or',
						Rules: [
							{ Property: 'DueDate', Operator: '>=', Value: moment().toISOString() },
							{ Property: 'DueDate', Operator: '=', Value: null }
						]
					});
					break;
				case 'planned':
					filterModel.Rules.push({ Property: 'StartDate', Operator: '>', Value: moment().startOf('day').toISOString() });
					break;
				case 'history':
					filterModel.Rules.push({ Property: 'DueDate', Operator: '<', Value: moment().toISOString() });
					break;
				default:
					break;
			}

			return filterModel;
		};

		service.getEntityIcon = function (operationalMessage) {
			if (operationalMessage.Equipment) return 'fa-gear c-bluegray';
			if (operationalMessage.Building) return 'fa-building-columns c-brown';
			if (operationalMessage.Estate) return 'fa-city c-teal';
		};

		service.getLowestEntityCaption = function (message) {
			if (!message)
				return "";

			if (message.Equipment) {
				var caption = "";
				caption = message.Equipment.Caption;
				if (message.Building)
					caption += " (" + message.Building.Id + ")";
				return caption;
			}

			if (message.Building)
				return message.Building.Caption;

			if (message.Estate)
				return message.Estate.Caption;
		};

		// Better name?
		// Return a UI enum to show a quick message about the state of the message depending on the dates compared to today.
		service.getCurrentStateEnum = function (message) {
			var state = "inactive";

			var startDateMoment = null;
			var dueDateMoment = null;
			if (message.StartDate)
				startDateMoment = moment(message.StartDate);
			if (message.DueDate)
				dueDateMoment = moment(message.DueDate);

			var isStartDateInThePast = !!startDateMoment && moment().isSameOrAfter(startDateMoment);
			var isStartDateInTheFuture = !!startDateMoment && moment().isBefore(startDateMoment);
			var isDueDateInTheFuture = !!dueDateMoment && moment().isBefore(dueDateMoment);
			var isDueDateInThePast = !!dueDateMoment && moment().isAfter(dueDateMoment);

			// Today is between start and end date (or no end)
			if (isStartDateInThePast && (isDueDateInTheFuture || !dueDateMoment))
				state = "active";
			else if (isStartDateInTheFuture)
				state = "planned";
			else if (isDueDateInThePast)
				state = "historic";
			return state;
		};

		service.getStartDateText = function (message) {
			if (!message || !message.StartDate)
				return "";

			try {
				var startDate = moment(message.StartDate).startOf('day');
				var today = moment().startOf('day');
				var dayDifference = startDate.diff(today, 'days');

				// Today is past startDate
				if (dayDifference < 0) {
					if (!message.DueDate) {
						if (dayDifference === -1)
							return translationService.translate('web-operationalMessage-startDateText-yesterday', 'Startet i går');
						if (dayDifference < -15)
							return translationService.translate('web-operationalMessage-startDateText-pastDate', 'Startet') + ' ' + $filter('date')(message.StartDate, 'dd.MM.yyyy');
						return translationService.translate('web-operationalMessage-startDateText-pastDays-prefix', 'Startet for') + ' ' + (dayDifference * -1) + ' ' + translationService.translate('web-operationalMessage-startDateText-pastDays-suffix', 'dager siden');
					}

					return "";
				}
					

				if (dayDifference === 0)
					return translationService.translate('web-operationalMessage-startDateText-today', 'Starter i dag');

				if (dayDifference === 1)
					return translationService.translate('web-operationalMessage-startDateText-tomorrow', 'Starter i morgen');

				if (dayDifference > 15)
					return translationService.translate('web-operationalMessage-startDateText-atDate', 'Starter') + ' ' + $filter('date')(message.StartDate, 'dd.MM.yyyy');

				return translationService.translate('web-operationalMessage-startDateText-atDays-prefix', 'Starter om') + ' ' + dayDifference + ' ' + translationService.translate('web-operationalMessage-startDateText-atDays-suffix', 'dager');
			} catch (error) {
				return "";
			}
		};

		service.getDueDateText = function (message) {
			if (!message || !message.DueDate)
				return "";

			try {
				var dueDate = moment(message.DueDate).startOf('day');
				var today = moment().startOf('day');
				var dayDifference = dueDate.diff(today, 'days');

				// Today is past dueDate
				if (dayDifference < 0)
					return "";

				if (dayDifference === 0)
					return translationService.translate('web-operationalMessage-dueDateText-today', 'Slutter i dag');

				if (dayDifference === 1)
					return translationService.translate('web-operationalMessage-dueDateText-tomorrow', 'Slutter i morgen');

				if (dayDifference > 30)
					return translationService.translate('web-operationalMessage-dueDateText-atDate', 'Slutter') + ' ' + $filter('date')(message.DueDate, 'dd.MM.yyyy');

				return translationService.translate('web-operationalMessage-dueDateText-atDays-prefix', 'Slutter om') + ' ' + dayDifference + ' ' + translationService.translate('web-operationalMessage-dueDateText-atDays-suffix', 'dager');
			} catch (error) {
				return "";
			}
		};

		service.createWorkOrder = function (operationalMessage) {
			if (operationalMessage.GuidWorkOrder) {
				swal(
					translationService.translate('web-operationalMessage-createWorkOrder-alreadyConnectedToWorkOrder-title', 'Kan ikke opprette arbeidsordre.'),
					translationService.translate('web-operationalMessage-createWorkOrder-alreadyConnectedToWorkOrder-message', 'Kan ikke opprette arbeidsordre for en driftsmelding som allerede er knyttet til en arbeidsordre.')
				);
				return;
			}

			var workOrder = {
				fromOperationalMessage: true,
				guidSelections: [operationalMessage.Guid],
				StartDate: new Date().toISOString(),
				defaultDescription: operationalMessage.Message
			};

			if (operationalMessage.GuidEstate) {
				workOrder.guidEstate = operationalMessage.GuidEstate;
				if (operationalMessage.Estate)
					workOrder.estate = [operationalMessage.Estate.Id, operationalMessage.Estate.Description].filter(function (x) { return !!x; }).join(" - ");
			}

			if (operationalMessage.GuidBuilding) {
				workOrder.guidBuilding = operationalMessage.GuidBuilding;
				if (operationalMessage.Building)
					workOrder.building = [operationalMessage.Building.Id, operationalMessage.Building.Description].filter(function (x) { return !!x; }).join(" - ");
			}

			if (operationalMessage.GuidEquipment) {
				workOrder.guidEquipment = operationalMessage.GuidEquipment;
				if (operationalMessage.Equipment)
					workOrder.equipment = [operationalMessage.Equipment.Id, operationalMessage.Equipment.Description].filter(function (x) { return !!x; }).join(" - ");
			}

			repository.persistedData.setPersistedData('workOrder.create', workOrder);
			$rootScope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		return service;
	}
})();
