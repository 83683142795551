(function () {
	angular.module('Plania').controller('EditDocumentController', ['$scope', 'Repository', '$stateParams', 'authService', 'TranslationService', 'DocumentService', 'ngTableParams', '$modal', '$window', '$modalStack', '$rootScope',
		function ($scope, repository, params, authService, translationService, documentService, ngTableParams, $modal, $window, $modalStack, $rootScope) {
			$scope.update = true;
			$scope.restrictEdit = !authService.hasEditAccess('Document', false);
			$scope.restrictDelete = !authService.hasDeleteAccess('Document', false);
			$scope.model = { Guid: params.guid };
			$scope.activateAutocomplete = false;
			$scope.isModal = params.isModal;
			$scope.isDynamicPropertiesCollapsed = false;
			$scope.modelDto = {};
			$scope.search = {};

			if ($scope.isModal)
				$scope.viewMode = false;
			else
				$scope.viewMode = $scope.update ? true : false;

			$scope.toggleViewMode = function () {
				$scope.viewMode = !$scope.viewMode;
				$scope.reload = true;
			};

			var supportsArchiving = function (documentCategory) {
				if (documentCategory) {
					return documentCategory.ArchiveType === 'Archivable' || documentCategory.ArchiveType === 'AutomaticArchiving';
				}
				return false;
			};

			$scope.openDocument = function (document) {
				documentService.openDocument(document);
			};

			$scope.autoCompleteFilter = function (filterName) {
				var filter = {};
				switch (filterName) {
					case "documentCategory":
						filter.PropertyFilter = [{ Property: 'IsAbstract', Operator: "=", Value: false }];
						return filter;
				}
				return filter;
			};

			$scope.replaceDocument = function () {
				$modal.open({
					templateUrl: 'app/document/views/replaceDocumentModal.html',
					controller: 'ReplaceDocumentController',
					size: 'md',
					resolve: {
						params: function () {
							return { model: $scope.model };
						}
					}
				}).result.then(function (filename) {
					//add equipments to document_x_entity
					if (filename) {
						$scope.model.FilePath = filename;
					}
				},
					function () {
						//cancel
					});
			};

			var reloadTable = function (prefix, all) {
				if (prefix === 'Equipment' || all)
					$scope.equipmentTable.reload();
				if (prefix === 'PeriodicTask' || all)
					$scope.periodicTaskTable.reload();
				if (prefix === 'Component' || all)
					$scope.componentTable.reload();
				if (prefix === 'Area' || all)
					$scope.areaTable.reload();
				if (prefix === 'Building' || all)
					$scope.buildingTable.reload();
				if (prefix === 'Estate' || all)
					$scope.estateTable.reload();
				if (prefix === 'Deviation' || all)
					$scope.deviationTable.reload();
				if (prefix === 'Condition' || all)
					$scope.conditionTable.reload();
				if (prefix === 'Supplier' || all)
					$scope.supplierTable.reload();
				if (prefix === 'Request' || all)
					$scope.requestTable.reload();
				if (prefix === 'WorkOrder' || all)
					$scope.workOrderTable.reload();
			};

			$scope.approveAllDocumentXEntity = function () {
				swal({
					title: translationService.translate('web-swal-document-approvalAll-title',
						'Vil du godkjenne dokument og alle referanser?'),
					text: translationService.translate('web-swal-equipment-approval-message',
						'Dokumentet og alle eksisterende referanser vil bli godkjent"'),
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#337ab7",
					confirmButtonText: translationService.translate('web-swal-equipment-button-confirm-approval',
						'Ja, godkjenn!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				},
					function () {
						window.onkeydown = null;
						window.onfocus = null;

						var success = function (result) {
							reloadTable("Building", true);
							repository.growl(translationService.translate('web-document-approveAll-sent-confirmation', 'Dokumentet med eksisterende referanser har blitt godkjent!'), 'success');
						};
						var updateError = function (result) {
							repository.growl(result, 'danger');
						};
						repository.runAction(repository.apiData.document.endpoint.approveAllDocumentXEntity + $scope.model.Guid)
							.then(success, updateError);
					});
			};

			$scope.setDataAcquisitionStatus = function (xref, status, prefix) {
				swal({
					title: translationService.translate('web-swal-document-setStatus-title',
						'Vil du sette datafangst status til ' +
						translationService.translate('web-enum-DataAcquisitionStatus-' + status, status) +
						'?'),
					text: translationService.translate('web-swal-equipment-approval-message',
						'Status vil bli til ' +
						translationService.translate('web-enum-DataAcquisitionStatus-' + status, status) +
						"!"),
					type: "info",
					showCancelButton: true,
					confirmButtonColor: "#337ab7",
					confirmButtonText: translationService.translate(
						'web-swal-equipment-button-confirm-set-dataAcquisitionStatus',
						'Ja, sett status!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: true
				},
					function () {
						window.onkeydown = null;
						window.onfocus = null;

						var success = function (result) {
							reloadTable(prefix, false);
							repository.growl(translationService.translate('web-document-dataAcquisition-status-updated', 'Status har blitt satt!'), 'success');
						};
						var updateError = function (result) {
							repository.growl(result, 'danger');
						};
						repository.runAction(repository.apiData.documentXEntity.endpoint.setDataAcquisitionStatus + xref.Guid, null, 'status=' + status)
							.then(success, updateError);
					});
			};

			$scope.setArchiveStatus = function (xref, status, prefix) {
				var properties = {
					ArchiveStatus: status
				};

				repository.patch(repository.apiData.documentXEntity.url, xref.Guid, properties).then(function (result) {
					reloadTable(prefix, false);
					repository.growl('Arkivstatus oppdatert', 'success');
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			$scope.checkbox = {
				isRegulatoryCompilanceDocumentation: false,
				isAttestationDocumentation: false,
				isDescriptionDocumentation: false,
				isOperationDocumentation: false,
				isMaintenanceDocumentation: false,
				isProductInfoDocumentation: false,
				isInstructionDocumentation: false,
				isProtocolDocumentation: false,
				isIdentificationDocumentation: false,
				isDrawingDocumentation: false,
				isImageDocumentation: false,
			};

			var getCheckbox = function () {
				var checkbox = 0;
				if ($scope.model.isRegulatoryCompilanceDocumentation)
					checkbox = checkbox | 1 << 0;
				if ($scope.model.isAttestationDocumentation)
					checkbox = checkbox | 1 << 1;
				if ($scope.model.isDescriptionDocumentation)
					checkbox = checkbox | 1 << 2;
				if ($scope.model.isOperationDocumentation)
					checkbox = checkbox | 1 << 3;
				if ($scope.model.isMaintenanceDocumentation)
					checkbox = checkbox | 1 << 4;
				if ($scope.model.isProductInfoDocumentation)
					checkbox = checkbox | 1 << 5;
				if ($scope.model.isInstructionDocumentation)
					checkbox = checkbox | 1 << 6;
				if ($scope.model.isProtocolDocumentation)
					checkbox = checkbox | 1 << 7;
				if ($scope.model.isIdentificationDocumentation)
					checkbox = checkbox | 1 << 8;
				if ($scope.model.isDrawingDocumentation)
					checkbox = checkbox | 1 << 9;
				if ($scope.model.isImageDocumentation)
					checkbox = checkbox | 1 << 10;

				return checkbox;
			};


			var columns = [
				'DocumentCategory.Description', 'DocumentType.Id',
				'Supplier.Id', 'Supplier.Description',
				'DocumentCategory.ArchiveType'
			];

			repository.getMainModel(repository.apiData.document.url, params.guid, JSON.stringify(columns)).then(
				function (response) {
					var document = response.Data;
					$scope.reload = true;
					$scope.model = document;


					if ($scope.model.Supplier)
						$scope.modelDto.supplier = $scope.getEntityCaption($scope.model.Supplier);

					//setCheckbox($scope.model.DocumentationContext);
					var isDocumentOtherDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid !== $scope.model.GuidDataOwner;
					$scope.restrictEdit = !authService.hasEditAccess('Document', isDocumentOtherDataOwner) || $scope.model.IsArchived;
					$scope.restrictDelete = !authService.hasDeleteAccess('Document', isDocumentOtherDataOwner) || $scope.model.IsArchived;

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);


					if (!$scope.isModal) {
						getRefListData();
					}

				},
				function (error) {
					repository.growl(error.Data ? error.Data.message : error.Message, 'danger');
				});

			$scope.menuOptions = function (prefix, xRef, guidProperty) {
				var options = [];

				if ($scope.hasReadAccess(prefix) && $rootScope.userInfo.enableWebNavigation) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-navigation-goto' + prefix, 'Gå til ' + translationService.translate('web-enum-prefix-' + prefix)) + '</a >',
						click: function () {
							$scope.navigation.transitionTo(prefix.charAt(0).toLowerCase() + prefix.substring(1) + '.edit', { guid: xRef[guidProperty] });
						}
					});
				}
				if ($scope.hasDeleteAccess('DocumentXEntity', prefix, false)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#">' + translationService.translate('web-document-deleteXref', 'Slett') + '</a >',
						click: function () {
							$scope.removeXEntity(xRef.Guid, prefix);
						}
					});
				}
				if ($scope.userInfo.canChangeDataAcquisitionStatus) {
					options.push(
						{
							html: '<a href="" class="dropdown-item"">' + translationService.translate('web-document-dataAcquisitionStatus-approve', 'Godkjenn') + '</a>',
							click: function () {
								$scope.setDataAcquisitionStatus(xRef, 'Approved', prefix);
							}
						});
					options.push(
						{
							html: '<a href="" class="dropdown-item">' + translationService.translate('web-document-dataAcquisitionStatus-reject', 'Avslått') + '</a>',
							click: function () {
								$scope.setDataAcquisitionStatus(xRef, 'Rejected', prefix);
							}
						});
				}
				if (xRef.ArchiveStatus === 'Archived') {
					options.push(
						{
							html: '<a href="" class="dropdown-item"">' + translationService.translate('web-document-archive-Show', 'Åpne i arkivsystemet') + '</a>',
							click: function () {
								$scope.openDocumentInArchiveSystem(xRef);
							}
						});
				}
				return options;
			};



			function getRefListData() {
				var getListData = function (filter, entityType, columns) {

					if ($scope.hasModule('DataAcquisition')) {
						columns.push('DataAcquisitionStatus');
					}
					if (supportsArchiving($scope.model.DocumentCategory)) {
						columns.push('ArchiveStatus');
						columns.push('JournalId');
					}

					var table = new ngTableParams(
						{
							page: 1,
							count: 10,
							filter: {
								EntityType: entityType,
								PropertyFilter: [{ Property: "GuidDocument", Operator: "=", Value: $scope.model.Guid }, filter]
							}
						},
						{
							total: 0,
							filterDelay: 10,
							counts: [10, 20, 50],
							getData: function ($defer, params) {
								repository.GetPaginated(repository.apiData.documentXEntity.url,
									params.page() - 1,
									params.count(),
									params.sorting(),
									params.filter(),
									null,
									JSON.stringify(columns)).then(
										function (result) {
											table.settings().total = result.TotalCount;
											table.settings().filterDelay = 500;
											$defer.resolve(result.List);
										},
										function (error) {
											repository.growl(error, 'danger');
										});
							}
						});
					return table;
				};

				$scope.equipmentTable = getListData({ Property: 'GuidEquipment', Operator: '<>', Value: '' }, repository.apiData.equipment.prefix, ['Equipment.Id', 'Equipment.Description', 'Equipment.Building.Id', 'Equipment.Building.Description']);
				$scope.periodicTaskTable = getListData({ Property: 'GuidPeriodicTask', Operator: '<>', Value: '' }, repository.apiData.periodicTask.prefix, ['PeriodicTask.Id', 'PeriodicTask.Description']);
				$scope.componentTable = getListData({ Property: 'GuidComponent', Operator: '<>', Value: '' }, repository.apiData.component.prefix, ['Component.Id', 'Component.Description']);
				$scope.estateTable = getListData({ Property: 'GuidEstate', Operator: '<>', Value: '' }, repository.apiData.estate.prefix, ['Estate.Id', 'Estate.Description']);
				$scope.buildingTable = getListData({ Property: 'GuidBuilding', Operator: '<>', Value: '' }, repository.apiData.building.prefix, ['Building.Id', 'Building.Description', 'Building.BuildingCategory.Description']);
				$scope.areaTable = getListData({ Property: 'GuidArea', Operator: '<>', Value: '' }, repository.apiData.area.prefix, ['Area.Id', 'Area.Description', 'Area.AreaCategory.Description']);
				$scope.deviationTable = getListData({ Property: 'GuidDeviation', Operator: '<>', Value: '' }, repository.apiData.deviation.prefix, ['Deviation.Id', 'Deviation.ActionComment', 'Deviation.Building.Id', 'Deviation.Building.Description', 'Deviation.Equipment.Id', 'Deviation.Equipment.Description']);
				$scope.conditionTable = getListData({ Property: 'GuidCondition', Operator: '<>', Value: '' }, repository.apiData.condition.prefix, ['Condition.Id', 'Condition.ActionComment', 'Condition.Building.Id', 'Condition.Building.Description', 'Condition.Equipment.Id', 'Condition.Equipment.Description']);
				$scope.supplierTable = getListData({ Property: 'GuidSupplier', Operator: '<>', Value: '' }, repository.apiData.supplier.prefix, ['Supplier.Id', 'Supplier.Description']);
				$scope.requestTable = getListData({ Property: 'GuidRequest', Operator: '<>', Value: '' }, repository.apiData.request.prefix, ['Request.Id', 'Request.Description', 'Request.Building.Id', 'Request.Building.Description']);
				$scope.workOrderTable = getListData({ Property: 'GuidWorkOrder', Operator: '<>', Value: '' }, repository.apiData.workOrder.prefix, ['WorkOrder.Id', 'WorkOrder.Description', 'WorkOrder.Building.Id', 'WorkOrder.Building.Description', 'WorkOrder.Status']);
			}


			$scope.editDocument = function (destination) {
				if (!$scope.model) {
					repository.growl('Could not save document', 'danger');
					return;
				}
				$scope.model.DocumentationContext = getCheckbox();

				repository.updateSingle(repository.apiData.document.url, $scope.model).then(
					function () {
						repository.growl('Dokumentet ble lagret', 'success');
						repository.commonService.setLastRegisterGuid(destination, $scope.model.Guid);
						if (!$scope.isModal) {
							$scope.goBack(destination, { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
						} else {
							$modalStack.dismissAll(true);
						}
					},
					function (error) {
						repository.growl("Kunne ikke lagre dokumentet - " + error, 'danger');
					});
			};


			$scope.onDocumentCategorySelect = function (category) {
				if (!category) {
					$scope.newDynamicProperty = null;
					$scope.model.GuidDocumentCategory = "";
					return;
				}
				if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
					$scope.newDynamicProperty = category.DynamicProperty;
				} else {
					$scope.newDynamicProperty = null;
				}
				$scope.model.GuidDocumentCategory = category.Guid;
			};

			$scope.cancel = function () {
				repository.commonService.setLastRegisterGuid("document", $scope.model.Guid);
				$modalStack.dismissAll();
			};


			var entityAttachmentMessage = function () {
				if ($scope.model.DocumentXEntities && $scope.model.DocumentXEntities.length > 0)
					return "Dokumentet er knyttet til et objekt";
				return "";
			};

			$scope.removeDocument = function () {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-document-message', "Dokumentet vil bli permanent fjernet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-document-button-confirm', 'Ja, fjern dokumentet'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.document.url, $scope.model.Guid)
						.then(function (result) {
							//TODO: Finne en bedre måte å løse dette på, helst på api siden med andre statuskoder.
							if (result === "Dokumentet ble ikke slettet, grunnet bindinger.") {
								swal("Feil!", result, "error");
							} else {
								swal('Suksess', translationService.translate('web-swal-document-success', 'Dokumentet ble fjernet!'), "success");
							}

							if ($scope.isModal)
								$modalStack.dismissAll();
							else
								$scope.goBack('document.list', { menuGuid: $scope.navigation.params.menuGuid });
						});
				});
			};

			$scope.removeXEntity = function (guidXRef, prefix) {
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-document-xEntity-message', "Referansen vil bli permanent fjernet!"),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-document-xEntity-button-confirm', 'Ja, fjern referansen'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;

					repository.deleteSingle(repository.apiData.documentXEntity.url, guidXRef)
						.then(function (result) {
							swal(translationService.translate('web-swal-document-xEntity-success', 'Referansen ble fjernet!'), result, "success");
							repository.growl('Referansen har blitt fjernet', 'success');
							reloadTable(prefix, false);
						}, function (error) {
							repository.growl(error, 'danger');
							swal("Error!", error, "error");
						});
				});
			};

			var addEquipmentModalParams = {
				title: 'Legg til anlegg',
				columns: [
					{ Title: 'Byggnr.', Property: 'Building.Id' },
					{ Title: 'Byggnavn', Property: 'Building.Description' },
					{ Title: 'Anleggsnr', Property: 'Id' },
					{ Title: 'Anleggsnavn', Property: 'Description' }
				],
				url: repository.apiData.equipment.url,
				sorting: { Id: 'asc' },
			};

			var addPeriodicTaskModalParams = {
				title: 'Legg til periodisk rutine',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' }
				],
				url: repository.apiData.periodicTask.url,
				sorting: { Id: 'asc' },
			};

			var addComponentModalParams = {
				title: 'Legg til Komponent',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' }
				],
				url: repository.apiData.component.url,
				sorting: { Id: 'asc' },
			};

			var addBuildingModalParams = {
				title: 'Legg til Byggning',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' }
				],
				url: repository.apiData.building.url,
				sorting: { Id: 'asc' },
			};

			var addAreaModalParams = {
				title: 'Legg til Rom',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' }
				],
				url: repository.apiData.area.url,
				sorting: { Id: 'asc' },
			};

			var addEstateModalParams = {
				title: 'Legg til Eiendom',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' }
				],
				url: repository.apiData.estate.url,
				sorting: { Id: 'asc' },
			};

			var addDeviationModalParams = {
				title: 'Legg til Avvik',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: translationService.translate('web-deviation-actionComment'), Property: 'ActionComment' },
					{ Title: 'Byggnr.', Property: 'Building.Id' },
					{ Title: 'Byggnavn', Property: 'Building.Description' },
					{ Title: 'Anleggsnr', Property: 'Equipment.Id' },
					{ Title: 'Anleggsnavn', Property: 'Equipment.Description' }
				],
				url: repository.apiData.deviation.url,
				sorting: { Id: 'desc' },
			};

			var addConditionModalParams = {
				title: 'Legg til Tilstand/Tiltak',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Tiltaksbeskrivelse', Property: 'ActionComment' },
					{ Title: 'Byggnr.', Property: 'Building.Id' },
					{ Title: 'Byggnavn', Property: 'Building.Description' },
					{ Title: 'Anleggsnr', Property: 'Equipment.Id' },
					{ Title: 'Anleggsnavn', Property: 'Equipment.Description' }
				],
				url: repository.apiData.condition.url,
				sorting: { Id: 'desc' },
			};

			var addSupplierModalParams = {
				title: 'Legg til leverandør',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' }
				],
				url: repository.apiData.supplier.url,
				sorting: { Id: 'desc' },
			};

			var addRequestModalParams = {
				title: 'Legg til melding',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' },
					{ Title: 'Byggnr.', Property: 'Building.Id' },
					{ Title: 'Byggnavn', Property: 'Building.Description' },
				],
				url: repository.apiData.request.url,
				sorting: { Id: 'desc' },
			};

			var addWorkOrderModalParams = {
				title: 'Legg til Arbeidsordre',
				columns: [
					{ Title: 'Id', Property: 'Id' },
					{ Title: 'Beskrivelse', Property: 'Description' },
					{ Title: 'Byggnr.', Property: 'Building.Id' },
					{ Title: 'Byggnavn', Property: 'Building.Description' },
				],
				url: repository.apiData.workOrder.url,
				sorting: { Id: 'desc' },
			};

			$scope.openDocumentInArchiveSystem = function (xRef) {
				$window.open(xRef.JournalId, '_blank');
			};

			$scope.addXEntity = function (entity) {

				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					size: entity === 'Deviation' || entity === 'Condition' || entity === "Request" || entity === "WorkOrder" ? 'lg' : 'md',
					resolve: {
						modalParams: function () {
							if (entity === 'Equipment') {
								addEquipmentModalParams.filter = {};
								if ($scope.equipmentTable.data.length > 0) {
									addEquipmentModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.equipmentTable.data, 'GuidEquipment').join(",")
										}
									];
								}
								return addEquipmentModalParams;
							}
							if (entity === 'PeriodicTask') {
								addPeriodicTaskModalParams.filter = {};
								if ($scope.periodicTaskTable.data.length > 0) {
									addPeriodicTaskModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.periodicTaskTable.data, 'GuidPeriodicTask').join(",")
										}
									];
								}
								return addPeriodicTaskModalParams;
							}
							if (entity === 'Component') {
								addComponentModalParams.filter = {};
								if ($scope.componentTable.data.length > 0) {
									addComponentModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.componentTable.data, 'GuidComponent').join(",")
										}
									];
								}
								return addComponentModalParams;
							}
							if (entity === 'Building') {
								addBuildingModalParams.filter = {};
								if ($scope.buildingTable.data.length > 0) {
									addBuildingModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.buildingTable.data, 'GuidBuilding').join(",")
										}
									];
								}
								return addBuildingModalParams;
							}
							if (entity === 'Area') {
								addAreaModalParams.filter = {};
								if ($scope.areaTable.data.length > 0) {
									addAreaModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.areaTable.data, 'GuidArea').join(",")
										}
									];
								}
								return addAreaModalParams;
							}
							if (entity === 'Estate') {
								addEstateModalParams.filter = {};
								if ($scope.estateTable.data.length > 0) {
									addEstateModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.estateTable.data, 'GuidEstate').join(",")
										}
									];
								}
								return addEstateModalParams;
							}
							if (entity === 'Deviation') {
								addDeviationModalParams.filter = {};
								if ($scope.deviationTable.data.length > 0) {
									addDeviationModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.deviationTable.data, 'GuidDeviation').join(",")
										}
									];
								}
								return addDeviationModalParams;
							}
							if (entity === 'Condition') {
								addConditionModalParams.filter = {};
								if ($scope.conditionTable.data.length > 0) {
									addConditionModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.conditionTable.data, 'GuidCondition').join(",")
										}
									];
								}
								return addConditionModalParams;
							}
							if (entity === 'Supplier') {
								addSupplierModalParams.filter = {};
								if ($scope.supplierTable.data.length > 0) {
									addSupplierModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.supplierTable.data, 'GuidSupplier').join(",")
										}
									];
								}
								return addSupplierModalParams;
							}
							if (entity === 'Request') {
								addRequestModalParams.filter = {};
								if ($scope.requestTable.data.length > 0) {
									addRequestModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.requestTable.data, 'GuidRequest').join(",")
										}
									];
								}
								return addRequestModalParams;
							}
							if (entity === 'WorkOrder') {
								addWorkOrderModalParams.filter = {};
								if ($scope.workOrderTable.data.length > 0) {
									addWorkOrderModalParams.filter.PropertyFilter = [
										{
											Property: 'Guid',
											Operator: 'notIn',
											Value: _.map($scope.workOrderTable.data, 'GuidWorkOrder').join(",")
										}
									];
								}
								return addWorkOrderModalParams;
							}
						}
					}
				}).result.then(function (selectedGuids) {
					//add equipments to document_x_entity
					var documentXEntities = [];
					selectedGuids.forEach(function (guid) {
						var xEntity =
						{
							GuidDocument: $scope.model.Guid,
							Entity: -1
						};
						if (entity === 'Equipment')
							xEntity.GuidEquipment = guid;
						if (entity === 'PeriodicTask')
							xEntity.GuidPeriodicTask = guid;
						if (entity === 'Component')
							xEntity.GuidComponent = guid;
						if (entity === 'Building')
							xEntity.GuidBuilding = guid;
						if (entity === 'Area')
							xEntity.GuidArea = guid;
						if (entity === 'Estate')
							xEntity.GuidEstate = guid;
						if (entity === 'Deviation')
							xEntity.GuidDeviation = guid;
						if (entity === 'Condition')
							xEntity.GuidCondition = guid;
						if (entity === 'Supplier')
							xEntity.GuidSupplier = guid;
						if (entity === 'Request')
							xEntity.GuidRequest = guid;
						if (entity === 'WorkOrder')
							xEntity.GuidWorkOrder = guid;

						documentXEntities.push(xEntity);
					});
					repository.createMultiple(repository.apiData.documentXEntity.url, documentXEntities).then(function (result) {
						repository.growl("Referansene har blitt lagt til", 'success');
						reloadTable(entity, false);
					}, function (result) {
						repository.growl(result, 'danger');
						reloadTable(entity, false);
					});
				},
					function () {
						//cancel
					});
			};
		}
	]);
})();
