(function () {

	var app = angular.module('Plania');

	app.directive('controlLists', function () {
		return {
			restrict: 'E',
			scope: {
				isCollapsed: '=isCollapsed',
				reload: '=reload',
				reloadDeviations: '=reloadDeviations',
				controlLists: '=controlLists',
				disableActions: '=disableActions',
				parentEntity: '=parentEntity',
				parentModel: '=parentModel',
				guid: '=guid',
				restrictEdit: '=restrictEdit',
			},
			controller: ['$scope', '$modal', 'ngTableParams', 'Repository', '$rootScope', 'TranslationService', "IconService", '$localStorage', 'SignalR', '$timeout', 'ColorService', controller],
			link: function (scope, element, attrs) {
			},
			templateUrl: 'app/controlList/views/controlListDirective.html'
		};
	});

	function controller($scope, $modal, ngTableParams, repository, $rootScope, translationService, iconService, $localStorage, signalR, $timeout, colorService) {
		$scope.controlListsIsLoading = false;
		$scope.addingControlListSignalR = false;
		$scope.search = {};
		$scope.isSVV = $localStorage.generalOptions.CustomerId === 'SvvTunnel' || $localStorage.generalOptions.CustomerId === 'Fylkeskommuner';
		$scope.isBBY = $localStorage.generalOptions.IsBBY;
		$scope.customization = $rootScope.customization;

		$scope.iconMap = iconService.GetIconMap();
		$scope.getIcon = iconService.getIcon;

		$scope.accessCreateControlListXEntity = function () {
			return $rootScope.hasEditAccess('ControlListXEntity') && $rootScope.hasReadAccess('ControlList') && ($scope.parentEntity === 'WorkOrder' || $scope.parentEntity === 'PeriodicTask');
		};

		$scope.hasReadAccess = $rootScope.hasReadAccess('ControlListXEntity');

		$scope.$watch('reload', function (newValue, oldValue) {
			if ($scope.reload && $scope.reload === true) {
				$scope.controlListTable.reload();
				$scope.reload = false;
			}
		});

		$scope.columns = [
			{ Position: 1, Title: translationService.translate('web-area-id'), Property: 'Area.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-area-description'), Property: 'Area.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-equipment-id'), Property: 'Equipment.Id', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-equipment-description'), Property: 'Equipment.Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-controllist-ismandatory'), Property: 'ControlList.IsMandatory', PropertyType: 'bool' },
			{ Position: 6, Title: translationService.translate('web-controllist-id'), Property: 'ControlList.Id', PropertyType: 'string' },
			{ Position: 7, Title: translationService.translate('web-controllist-description'), Property: 'ControlList.Description', PropertyType: 'string' },
			{ Position: 8, Title: translationService.translate('web-controllist-name'), Property: 'ControlList.Name', PropertyType: 'string' },
			{ Position: 9, Title: translationService.translate('web-controllist-icon'), Property: 'ControlList.Icon', PropertyType: 'string' },
			{ Position: 10, Title: translationService.translate('web-controllist-Color'), Property: 'ControlList.Color', PropertyType: 'string' },
		];

		//Returns the entity connected to the the controlList that is the lowest in the hierarchy
		$scope.getLowestEntityCaption = function (controlList) {
			if (controlList.Equipment) {
				var extraText = "";
				if ($scope.isSVV && controlList.Equipment && controlList.Equipment.Text20)
					extraText = " - " + controlList.Equipment.Text20;
				return $rootScope.getEntityCaption(controlList.Equipment) + extraText;
			}
			if (controlList.Area) {
				if ($localStorage.generalOptions.IsBBY) {
					return $rootScope.getEntityCaption(controlList.Area) + " - " + controlList.Area.Text01;
				}
				return $rootScope.getEntityCaption(controlList.Area);
			}
			if (controlList.Building) return $rootScope.getEntityCaption(controlList.Building);
			if (controlList.Estate) return $rootScope.getEntityCaption(controlList.Estate);
		};

		$scope.getEntityIcon = function (controlList) {
			if (controlList.Equipment) return 'fa-gear c-bluegray';
			if (controlList.Area) return 'fa-layer-group c-lightblue';
			if (controlList.Building) return 'fa-building-columns c-brown';
			if (controlList.Estate) return 'fa-city c-teal';
		};

		var sorting = {
			'ClosedDate': 'asc',
			'Area.Id': 'asc',
			'Equipment.Id': 'asc',
			'CreationDate': 'desc'
		};

		$scope.filterTypes = [
			{ value: 'all', name: translationService.translate('web-controlListXEntity-filterType-all', 'Alle'), fullName: translationService.translate('web-controlListXEntity-filterType-all-fullName', 'Vis alle kontrollister') },
			{ value: 'new', name: translationService.translate('web-controlListXEntity-filterType-new', 'Nye'), fullName: translationService.translate('web-controlListXEntity-filterType-new-fullName', 'Vis nye kontrollister') },
			{ value: 'active', name: translationService.translate('web-controlListXEntity-filterType-active', 'Aktive'), fullName: translationService.translate('web-controlListXEntity-filterType-active-fullName', 'Vis aktive kontrollister') },
			{ value: 'inworks', name: translationService.translate('web-controlListXEntity-filterType-inworks', 'Pågående'), fullName: translationService.translate('web-controlListXEntity-filterType-inworks-fullName', 'Vis pågående kontrollister') },
			{ value: 'completed', name: translationService.translate('web-controlListXEntity-filterType-completed', 'Fullførte'), fullName: translationService.translate('web-controlListXEntity-filterType-completed-fullName', 'Vis fullførte kontrollister') }
		];

		$scope.selectedFilter = _.find($scope.filterTypes, { value: $scope.parentEntity === "PeriodicTask" ? 'all' : 'active' });

		$scope.setFilter = function (filterType) {
			$scope.selectedFilter = _.find($scope.filterTypes, { value: filterType });
			$scope.controlListTable.reload();
		};

		$scope.controlListTable = new ngTableParams({
			page: 1,
			count: 5,
			sorting: sorting,
			filter: {}
		}, {
			total: 0,
			counts: [5, 10, 20],
			filterDelay: 50,
			paginationMaxBlocks: 6,
			getData: function ($defer, params) {
				if (!$scope.guid || !$scope.parentEntity) return;
				$scope.controlListsIsLoading = true;

				var columns = [];
				$scope.columns.forEach(function (col) {
					columns.push(col.Property);
				});

				columns.push('ControlList.Guid');
				columns.push('ControlList.UseImage');
				columns.push('CreationDate');
				columns.push('Status');
				columns.push('ClosedDate');
				columns.push('NotExecutedComment');
				columns.push('ReferenceDataNotExecuted.Id');
				columns.push('ReferenceDataNotExecuted.Description');
				columns.push('ControlList.VersionStatus');
				columns.push('ControlList.GuidReferenceTypeNotExecuted');
				columns.push('UserClosedBy.RealName');
				columns.push('UserUpdatedBy.RealName');
				columns.push('WorkOrder.Id', 'WorkOrder.Description', 'WorkOrder.Building.Id', 'WorkOrder.Building.Description', 'WorkOrder.Building.Guid');
				columns.push('Equipment.Building.Id', 'Equipment.Building.Description', 'Equipment.Building.Guid', 'Equipment.Manufacturer', 'Equipment.Location');
				columns.push('Equipment.Building.Estate.Id', 'Equipment.Building.Estate.Description', 'Equipment.Building.Estate.Guid');
				columns.push('Area.IsHousing');
				columns.push('Area.Building.Id', 'Area.Building.Description', 'Area.Building.Guid');
				columns.push('Area.Building.Estate.Id', 'Area.Building.Estate.Description', 'Area.Building.Estate.Guid');

				// Refresh just in case.
				$scope.isBBY = $localStorage.generalOptions.IsBBY;
				if ($scope.isBBY) {
					columns.push('Area.Text01', 'Area.AreaCategory.Description');
					columns.push('Equipment.Area.Id', 'Equipment.Area.Text01');
				}

				if ($scope.isSVV)
					columns.push('Equipment.Text20');

				if ($localStorage.generalOptions.CustomerId === 'UnoX')
					columns.push('Equipment.AlternateId');

				if ($scope.parentEntity === 'Area' || $scope.parentEntity === 'Equipment') columns.push('WorkOrder.EndDate', 'WorkOrder.Stage');

				var filter = {
					searchParams: params.filter().searchParams,
					FilterModel: {
						Condition: "and",
						Rules: [
							{ Property: 'Guid' + $scope.parentEntity, Operator: '=', Value: $scope.guid }
						]
					},
					ExcludeSelectionFilter: true
				};

				if ($scope.parentEntity === 'Area' || $scope.parentEntity === 'Equipment') {
					filter.FilterModel.Rules.push({ Property: 'GuidWorkOrder', Operator: '<>', Value: null });
				}

				var sorting = {};

				switch ($scope.selectedFilter.value) {
					case 'new':
						filter.FilterModel.Rules.push({ Property: 'Status', Operator: '=', Value: '0' });
						break;
					case 'active':
						filter.FilterModel.Rules.push({ Property: 'Status', Operator: 'in', Value: '0,1' });
						sorting = {
							"Status": "desc"
						};
						break;
					case 'inworks':
						filter.FilterModel.Rules.push({ Property: 'Status', Operator: 'in', Value: '1' });
						break;
					case 'completed':
						filter.FilterModel.Rules.push({ Property: 'Status', Operator: '=', Value: '2' });
						break;
				}

				Object.keys(params.sorting()).forEach(function (key) {
					sorting[key] = params.sorting()[key];
				});

				repository.GetPaginated(repository.apiData.controlListXEntity.url, params.page() - 1, params.count(), sorting, filter, null, JSON.stringify(columns)).then(function (result) {
					$scope.controlListTable.settings().total = result.TotalCount;
					$scope.controlListTable.settings().filterDelay = 500;
					$scope.controlListsIsLoading = false;

					if (!$scope.controlLists)
						$scope.controlLists = [];

					if ($scope.controlLists) {
						while ($scope.controlLists.length > 0)
							$scope.controlLists.pop();
					}

					result.List.forEach(function (controlList) {
						if (controlList.ControlList && controlList.ControlList.Color)
							controlList.ControlList.Color = colorService.convertToRainbow(controlList.ControlList.Color);
						$scope.controlLists.push(controlList);
					});

					$defer.resolve(result.List);
				}, function (error) {
					$scope.controlListsIsLoading = false;
					repository.showError(error);
				});
			}
		});

		$scope.create = function () {
			$modal.open({
				templateUrl: 'app/controlList/views/addControlListModal.html',
				controller: 'AddControlListModalController',
				size: 'xl',
				resolve: {
					params: function () {
						return {
							guidWorkOrder: $scope.parentEntity === 'WorkOrder' ? $scope.guid : null,
							guidPeriodicTask: $scope.parentEntity === 'PeriodicTask' ? $scope.guid : null,
							guidBuilding: $scope.parentModel.GuidBuilding,
							guidEstate: $scope.parentModel.GuidEstate,
							guidEquipment: $scope.parentModel.GuidEquipment,
							guidArea: $scope.parentModel.GuidArea,
						};
					}
				}
			}).result.then(function (response) {
				$scope.controlListTable.reload();
			}, function () {
				$scope.controlListTable.reload();
			});
		};

		$scope.openControlList = function (controlListXEntity) {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'xl',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							controlList: controlListXEntity.ControlList,
							controlListXEntity: controlListXEntity,
							workOrderCaption: $scope.parentModel.Caption,
							workOrder: $scope.parentModel,
							restrictEdit: (controlListXEntity.WorkOrder && controlListXEntity.WorkOrder.EndDate) || $scope.parentModel.EndDate || $scope.parentEntity === 'PeriodicTask' || $scope.restrictEdit ? true : false
						};
					}
				}
			}).result.then(function (result) {
				$scope.controlListTable.reload();
				$scope.reloadDeviations = true;
			}, function (reloadLists) {
				$scope.controlListTable.reload();
				$scope.reloadDeviations = true;
			});
		};

		$scope.remove = function (item) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-controlList-message', 'Kontrollisten vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-controlList-button-confirm', 'Ja, fjern kontrollisten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				repository.deleteSingle(repository.apiData.controlListXEntity.url, item.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-controlList-success', 'Kontrollisten ble fjernet!'), result, "success");
						$scope.controlListTable.reload();
					}, function (error) {
						swal('Error', error, "error");
					});
			});
		};

		// SignalR
		if ($scope.parentEntity === 'WorkOrder' && $scope.guid) {
			$scope.signalRData = {};
			$scope.showAddingControlListWorkOrderMessage = false;
			signalR.on('addingControlListsToWorkOrder' + $scope.guid, function (progress) {
				// Increase both with 1, in order to show correct progress bar.
				progress.Current++;
				progress.Total++;

				if (!$scope.showAddingControlListWorkOrderMessage && progress.Started) $scope.showAddingControlListWorkOrderMessage = true;
				if ($scope.showAddingControlListWorkOrderMessage && progress.Finished) {
					$timeout(function () {
						$scope.showAddingControlListWorkOrderMessage = false;
					}, 5000);
				}

				$scope.addControlListToWorkOrderData = progress;
				if (progress.Finished) {
					$timeout(function () {
						$scope.controlListTable.reload();
					}, 250);
				}
			});

			$scope.$on('$destroy', function () {
				signalR.off('addingControlListsToWorkOrder' + $scope.guid);
			});
		}

		$scope.$on($rootScope.events.newSelection, function () {
			$scope.controlListTable.reload();
		});
	}
})();
