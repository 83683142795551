(function () {
	var app = angular.module('Plania');
	app.directive('areaEquipmentDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				reloads: '=reloads',
				update: '=update',
				currentTab: '=currentTab',
			},
			controller: ['$scope', 'Repository', 'TranslationService', '$rootScope', '$timeout', 'NgTableParams', '$modal', '$localStorage', controller],
			templateUrl: 'app/area/views/areaEquipment.html'
		};
	});

	function controller($scope, repository, translationService, $rootScope, $timeout, ngTableParams, $modal, $localStorage) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasEditAccess = $rootScope.hasEditAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.userInfo = $rootScope.userInfo;
		$scope.customization = $rootScope.customization;
		$scope.generalOptions = $localStorage.generalOptions;
		$scope.imageApiUrl = $rootScope.imageApiUrl;
		$scope.navigation = $rootScope.navigation;

		if (!$scope.update) return;

		$scope.hasPageLoaded = $scope.currentTab === 'equipment';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'equipment' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="fas' + icon + ' fa-hc-fw fa-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		$scope.componentInfo = new function () {
			var me = this;

			this.items = [];
			this.search = {};

			this.columns = [
				{ Position: 1, Title: translationService.translate('web-component-id'), Property: 'Component.Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-component-description'), Property: 'Component.Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-componentCategory-description'), Property: 'Component.ComponentCategory.Description', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-componentxequipment-quantity', 'Antall'), Property: 'Quantity', PropertyType: 'string' },
			];

			this.initTable = function () {
				if (me.table) return;
				me.table = new ngTableParams(
					{
						page: 1,
						count: 25,
						sorting: { 'Component.Id': 'asc' },
						filter: { PropertyFilter: [{ Property: "GuidArea", Operator: "=", Value: $scope.model.Guid }] }
					},
					{
						total: 0,
						filterDelay: 10,
						counts: [10, 20, 50],
						paginationMaxBlocks: 6,
						getData: function ($defer, params) {
							var columns = [];
							me.columns.forEach(function (col) {
								columns.push(col.Property);
							});

							columns.push('Note');
							columns.push('Component.Guid');
							columns.push('Component.GuidImage');

							repository.GetPaginated(repository.apiData.componentArea.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
								me.items = result.List;
								me.table.settings().total = result.TotalCount;
								me.table.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}, function (error) {
								repository.showError(error);
							});
						}
					});
			};

			this.add = function () {
				$modal.open({
					templateUrl: 'app/common/views/multipleSelectModal.html',
					controller: 'MultipleSelectModalController',
					resolve: {
						modalParams: function () {
							return {
								title: 'Legg til komponent',
								url: repository.apiData.component.url,
								columns: [
									{ Title: translationService.translate('web-component-id'), Property: 'Id', PropertyType: 'string' },
									{ Title: translationService.translate('web-component-description'), Property: 'Description', PropertyType: 'string' },
								],
								filter: {
									FilterModel: {
										Condition: "and",
										Rules: [{
											Property: "ComponentXAreas.GuidArea",
											Operator: "<>",
											Value: $scope.model.Guid,
											CollectionOperator: "all"
										}]
									}
								},
								useObjects: false
							};
						}
					}
				}).result.then(function (guids) {
					var model = [];

					guids.forEach(function (guid) {
						model.push({ GuidArea: $scope.model.Guid, GuidComponent: guid });
					});

					repository.createMultiple(repository.apiData.componentArea.url, model).then(function (result) {
						me.table.reload();
					}, function () {
						me.table.reload();
					});
				});
			};

			this.update = function (item) {
				if (!me.canEdit(item)) return;

				$modal.open({
					templateUrl: 'app/area/views/editComponentArea.html',
					controller: 'AreaComponentController',
					resolve: {
						params: function () { return { component: item, AddComponents: false }; }
					}
				}).result.then(function () {
					me.table.reload();
				});
			};

			this.remove = function (item) {
				if (!me.canDelete(item)) return;
				swal({
					title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
					text: translationService.translate('web-swal-equipment-component-message', 'Komponenten vil bli permanent fjernet!'),
					type: "warning",
					showCancelButton: true,
					confirmButtonColor: "#f44336",
					confirmButtonText: translationService.translate('web-swal-equipment-component-button-confirm', 'Ja, fjern komponenten!'),
					cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
					closeOnConfirm: false,
					showLoaderOnConfirm: true
				}, function () {
					window.onkeydown = null;
					window.onfocus = null;
					repository.deleteSingle(repository.apiData.componentArea.url, item.Guid)
						.then(function (result) {
							swal(translationService.translate('web-swal-equipment-component-success', 'Komponenten ble fjernet!'), result, "success");
							me.table.reload();
						});
				});
			};

			this.contextMenuOptions = function (item) {
				var options = [];

				if ($scope.hasReadAccess('Component')) {
					options.push(getMenuLink($scope.navigation.href('component.edit', { guid: item.GuidComponent }), 'fa-arrow-right fa-hc-fw fa-hc-lg', translationService.translate('web-area-componentXAreaContextMenu-viewComponent', 'Gå til komponent')));
				}

				if (me.canEdit(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"> ' + translationService.translate('web-button-edit', 'Rediger') + '</a >',
						click: function () {
							me.update(item);
						}
					});
				}

				if (me.canDelete(item)) {
					options.push({
						html: '<a class="dropdown-item" tabindex= "-1" href= "#"> ' + translationService.translate('web-button-delete', 'Slett') + '</a >',
						click: function () {
							me.remove(item);
						}
					});
				}


				return options;
			};

			this.canEdit = function (item) {
				return item &&
					$scope.hasEditAccess('ComponentXArea');
			};

			this.canDelete = function (item) {
				return item &&
					$scope.hasDeleteAccess('ComponentXArea');
			};

			me.initTable();
		}();
	}
})();
